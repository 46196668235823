import { useState, useEffect, createContext } from "react";
import useActiveCourse from "../hooks/useActiveCourse";
import { type Locale, type TranslationSetKeys, translationSets, type UnPromisify, locales } from "./sets";

const courseLocales: { [key: string]: Locale } = {
  eigo: "ja",
  ingles: "es",
  default: "en",
};

// Declare translate function type

declare function TranslateFunction<TSetKey extends TranslationSetKeys = "general", TLocale extends Locale = "en">(
  key: keyof UnPromisify<ReturnType<(typeof translationSets)[TSetKey][TLocale]>>,
  /** pass JSX element as string and parse outcome of translation function - kind of a hack because this doesn't support react router links */
  replacers?: { [key: string]: string },
  /** use plural form of string */
  count?: number,
): string;

declare function TranslateFromGivenSetFunction<TSetData extends Record<string, string>, TKey extends keyof TSetData>(
  obj: TSetData,
  key: TKey,
  replacers?: { [key: string]: string },
): string;

export const TranslationContext = createContext<{
  t: typeof TranslateFunction;
  tObj: typeof TranslateFromGivenSetFunction;
  locale: Locale;
}>({
  // @ts-ignore
  t: (key: keyof typeof locales.en.general) => locales.en["general"]?.[key] || "",
  tObj: () => "",
  locale: "en",
});

export function TranslationProvider(props: { children: any }) {
  const activeCourse = useActiveCourse();
  const [locale, _setLocale] = useState<Locale>("en");
  const [localeLoading, setLocaleLoading] = useState<Promise<any> | null>(null);

  async function loadTranslationSet(locale: Locale, key: TranslationSetKeys, updateLoadingState = true) {
    const promise = translationSets[key][locale]();
    if (updateLoadingState) {
      setLocaleLoading(promise);
    }
    const importValue = await promise;
    locales[locale] = {
      ...locales[locale],
      [key]: importValue,
    };
    if (updateLoadingState) {
      setLocaleLoading(null);
    }
  }

  // When the active course changes, update the locale
  useEffect(() => {
    if (!activeCourse?.slug) {
      return;
    }

    function setLocale(locale: Locale) {
      if (locales[locale]["general"]) {
        _setLocale(locale);
      } else {
        loadTranslationSet(locale, "general").then(() => {
          _setLocale(locale);
        });
      }
    }

    setLocale(courseLocales[activeCourse.slug] || "en");
  }, [activeCourse]);

  function translateFromObject<TSetData extends Record<string, string>, TKey extends keyof TSetData>(
    obj: TSetData,
    key: TKey,
    replacers?: { [key: string]: string },
    count?: number,
  ): string {
    let translation: string = obj[key] || "";

    if (!translation) {
      return "";
    }

    if (translation.includes("|")) {
      const plurals = translation.split("|");
      const plural = count === 0 || (count || 0) > 1;
      // Some languages only have singular and plural forms
      translation = (plural ? plurals[1] : plurals[0]) || plurals[0] || "";
    }

    if (replacers) {
      Object.keys(replacers).forEach((replacerKey) => {
        const regex = new RegExp(`{${replacerKey}}`, "g");
        const value = replacers[replacerKey] || "";
        translation = translation.replace(regex, value);
      });
    }

    return translation;
  }

  function translate<TSetKey extends TranslationSetKeys = "general", TLocale extends Locale = "en">(
    key: keyof UnPromisify<ReturnType<(typeof translationSets)[TSetKey][TLocale]>>,
    replacers?: { [key: string]: string },
    count?: number,
  ) {
    const translationSetByLocale = locales[locale]["general"];

    // Throw promise if translation set hasn't yet loaded
    if (!translationSetByLocale) {
      const promises = [loadTranslationSet(locale, "general", false)];
      if (locale !== "en") {
        promises.push(loadTranslationSet("en", "general", false));
      }
      throw Promise.all(promises);
    }

    let translation: string = (() => {
      if (key in translationSetByLocale) {
        // @ts-ignore
        return translationSetByLocale[key];
      }
      // @ts-ignore
      return locales.en.general[key];
    })();

    if (!translation) {
      return "";
    }

    if (translation.includes("|")) {
      const plurals = translation.split("|");
      const plural = count === 0 || (count || 0) > 1;
      translation = (plural ? plurals[1] : plurals[0]) || plurals[0] || "";
    }

    if (replacers) {
      Object.keys(replacers).forEach((replacerKey) => {
        const regex = new RegExp(`{${replacerKey}}`, "g");
        const value = replacers[replacerKey];
        // Some languages only have singular and plural forms
        translation = translation.replace(regex, value || "");
      });
    }

    return translation;
  }

  if (localeLoading) {
    throw localeLoading;
  }

  return (
    <TranslationContext.Provider value={{ t: translate, tObj: translateFromObject, locale }}>
      {props.children}
    </TranslationContext.Provider>
  );
}
