import { createContext, useContext, useEffect, useRef } from "react";
import LoadingBar from "../LoadingBar/LoadingBar";
import ReactDOM from "react-dom";
import classes from "./Modal.module.scss";
import { clsx } from "clsx";
import { FiX } from "react-icons/fi";

interface ModalContainerProps {
  /** When set to true, ignores taps on the overlay that would otherwise call `onClose()` */
  sticky?: boolean;
  isOpen?: boolean;
  onClose?: VoidFunction;
  zIndex?: number;
  children: React.ReactNode;
  bodyClass?: string;
  verticallyCentered?: boolean;
}

const ModalContext = createContext<{ onClose?: VoidFunction }>({});

/**
 * @example
 *
 ```jsx
 import { Modal, Button } from "rocket/ui";

 function MyModalUI() {
   return (
      <Modal.CardContainer isOpen>
        <Modal.Title>Modal title</Modal.Title>
        <Modal.Body>The modal body</Modal.Body>
        <Modal.Actions>
          <Button>Do something</Button>
        </Modal.Actions>
      </Modal.CardContainer>
    );
 }
 ```
 */
const Modal = {
  Container(props: ModalContainerProps) {
    const overlayRef = useRef<HTMLDivElement>(null);
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      function onKeyPress(ev: KeyboardEvent) {
        if (ev.key === "Escape" && props.onClose) {
          props.onClose();
        }
      }

      document.addEventListener("keydown", onKeyPress);
      return () => {
        document.removeEventListener("keydown", onKeyPress);
      };
    }, [props]);

    useEffect(() => {
      if (props.isOpen && modalRef.current) {
        modalRef.current.focus();
      }
    }, [props.isOpen]);

    const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
      if (props.onClose && overlayRef.current === e.target) {
        props.onClose();
      }
    };

    if (!props.isOpen) {
      return null;
    }

    return ReactDOM.createPortal(
      <ModalContext.Provider value={{ onClose: props.onClose }}>
        <div
          className={classes.modalOverlay}
          ref={overlayRef}
          onClick={!props.sticky ? handleOverlayClick : undefined}
          style={{
            pointerEvents: !props.isOpen ? "none" : "initial",
            position: "fixed",
            zIndex: props.zIndex || 49,
          }}
        />
        <div
          ref={modalRef}
          tabIndex={-1}
          data-scroll-lock-scrollable
          className="fixed top-0 z-50 max-h-screen w-screen overflow-auto"
        >
          <div
            className={clsx(classes.modalBody, "mx-auto", props.bodyClass)}
            aria-modal={true}
            aria-haspopup={true}
            style={{ zIndex: props.zIndex || 999 }}
          >
            {props.children}
          </div>
        </div>
      </ModalContext.Provider>,
      document.querySelector("body") as Element,
    );
  },

  CardContainer({
    bodyClass,
    isOpen,
    sticky,
    children,
    onClose,
    loading,
    verticallyCentered,
  }: ModalContainerProps & { loading?: boolean }) {
    return (
      <Modal.Container
        isOpen={isOpen}
        onClose={onClose}
        sticky={sticky}
        bodyClass={clsx(bodyClass, verticallyCentered && "items-center min-h-[90vh]")}
      >
        <div className={clsx(classes.cardContainer, "bg-white p-10 dark:bg-surface2")}>
          {loading && <LoadingBar className="left-0 top-0" />}
          {children}
        </div>
      </Modal.Container>
    );
  },

  Title({
    variant,
    hideCloseButton,
    className,
    children,
  }: {
    variant?: "sticky-centered";
    hideCloseButton?: boolean;
    className?: string;
    children: React.ReactNode;
  }) {
    const modal = useContext(ModalContext);
    return (
      <div className={clsx(classes.title, "pb-10", variant && classes[variant], className)}>
        {typeof children === "string" ? <h2 className="font-sans">{children}</h2> : children}
        {variant !== "sticky-centered" && !hideCloseButton && (
          <button
            className={
              "hover:bg-surface1-dark active:bg-surface1-dark relative ml-4 flex h-[48px] w-[48px] min-w-[48px] items-center justify-center whitespace-nowrap rounded-full bg-surface1 p-0 py-2 text-2xl font-semibold text-rocketgreen-alt focus:ring dark:hover:bg-neutral-900 dark:active:bg-neutral-900"
            }
            aria-label={"Close"}
            type="button"
            onClick={modal.onClose}
          >
            <FiX />
          </button>
        )}
      </div>
    );
  },

  Body({ className, ...rest }: React.HTMLAttributes<HTMLDivElement>) {
    return <div className={clsx("pt-4", className)} {...rest} />;
  },

  Actions(props: { left?: JSX.Element; right?: JSX.Element; children?: React.ReactNode }) {
    return (
      <div className={clsx(classes.actions, "mt-4 pt-4")}>
        {props.left && <div className={classes.actionsLeft}>{props.left}</div>}
        {props.right && <div className={classes.actionsRight}>{props.right}</div>}
        {props.children && <div className={classes.actionsRight}>{props.children}</div>}
      </div>
    );
  },
};

export default Modal;
