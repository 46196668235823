import type {
  LessonTypeCode,
  MultiuserAccountStatusName,
  MultiuserAccountStatusSlug,
  MultiuserAccountTypeSlug,
  PermissionTypes,
} from "@rocketlanguages/types";

/**
 * References the column IDs from `lv_languages`.
 *
 * Used in `lv_lesson_components.language_id` for travelogue language toggles
 */
export const Languages = [
  { id: 1, name: "Chinese" },
  { id: 2, name: "French" },
  { id: 3, name: "German" },
  { id: 4, name: "Italian" },
  { id: 5, name: "Japanese" },
  { id: 6, name: "Sign Language" },
  { id: 7, name: "Spanish" },
  { id: 8, name: "Arabic" },
  { id: 9, name: "Korean" },
  { id: 10, name: "Hindi" },
  { id: 11, name: "Ingles" },
  { id: 12, name: "Eigo" },
  { id: 13, name: "Portuguese" },
  { id: 15, name: "English" },
  { id: 16, name: "Russian" },
  { id: 17, name: "Portuguese" },
];

export const PRODUCT_LEVEL_LABEL_BY_ID = {
  1: "Level 1",
  2: "Level 2",
  3: "Level 3",
  4: "Travelogue",
  // eBook
  9: "Audio Book",
  10: "Play the Part",
} as const;

/**
 * List of Rateable Test Type IDs in the DB, keyed by Test Name
 *
 * Note: assign const so that we can infer the values in TS
 */
export const RateableTestTypeIds = {
  HEAR_IT: 1 as const,
  WRITE_IT: 2 as const,
  WRITE_IT_NATIVE: 3 as const,
  KNOW_IT: 4 as const,
  QUIZ: 5 as const,
  FLASHCARDS: 11 as const,
  CUSTOM_FLASHCARDS: 12 as const,
  TRANSLATE_IT: 13 as const,
  SORT_IT: 15 as const,
  SORT_IT_NATIVE: 16 as const,
  DRAW_IT: 17 as const,
};

export const RateableTestLocaleCodeArray = [
  "hear-it-say-it",
  "write-it",
  "write-it-native",
  "know-it",
  "quiz",
  "flashcards",
  "translate-it",
  "sort-it",
  "sort-it-native",
  "draw-it",
  "play-it",
] as const;

export type RateableTestLocaleCode = (typeof RateableTestLocaleCodeArray)[number];

export const RateableTestTypes: Record<number, { code: RateableTestLocaleCode; name: string; description: string }> = {
  [RateableTestTypeIds.HEAR_IT]: {
    code: "hear-it-say-it",
    name: "Listening",
    description: "Listen to the phrase, then record yourself saying the phrase",
  },
  [RateableTestTypeIds.WRITE_IT]: {
    code: "write-it",
    name: "Writing",
    description: "Listen to the phrase, then type what you heard",
  },
  [RateableTestTypeIds.WRITE_IT_NATIVE]: {
    code: "write-it-native",
    name: "Writing",
    description: "Listen to the phrase, then type what you heard",
  },
  [RateableTestTypeIds.KNOW_IT]: {
    code: "know-it",
    name: "Speaking",
    description: "Record yourself saying the phrase out loud",
  },
  [RateableTestTypeIds.QUIZ]: {
    code: "quiz",
    name: "Quiz",
    description: "Answer the multi-choice questions to reinforce your understanding",
  },
  [RateableTestTypeIds.FLASHCARDS]: {
    code: "flashcards",
    name: "Flashcards",
    description: "Reinforce your memory using Flashcards",
  },
  [RateableTestTypeIds.TRANSLATE_IT]: {
    code: "translate-it",
    name: "Signing",
    description: "Watch the video and choose the correct translation",
  },
  [RateableTestTypeIds.SORT_IT]: {
    code: "sort-it",
    name: "Composing",
    description: "Drag and drop the words to form the phrase",
  },
  [RateableTestTypeIds.SORT_IT_NATIVE]: {
    code: "sort-it-native",
    name: "Composing",
    description: "Drag and drop the words to form the phrase",
  },
  [RateableTestTypeIds.DRAW_IT]: {
    code: "draw-it",
    name: "Drawing",
    description: "Draw the character using the correct stroke order",
  },
};

/**
 * List of Writing system IDs in the DB, keyed by writing system name
 */
export const WritingSystemIds = {
  english: 1,
  hiragana_katakana: 2,
  kanji: 3,
  romaji: 4,
  spanish: 5,
  arabic: 6,
  arabic_romanized: 7,
  pinyin: 8,
  hanzi: 9,
  french: 10,
  german: 11,
  hindi: 12,
  hindi_romanized: 13,
  italian: 14,
  korean: 15,
  korean_romanized: 16,
  portuguese: 17,
  esl: 18,
  russian_romanized: 19,
  russian: 20,
} satisfies Record<string, number>;

export const WritingSystemLanguages = {
  [WritingSystemIds.english]: "English",
  [WritingSystemIds.hiragana_katakana]: "Kana",
  [WritingSystemIds.kanji]: "Kanji",
  [WritingSystemIds.romaji]: "Romaji",
  [WritingSystemIds.spanish]: "Spanish",
  [WritingSystemIds.arabic]: "Arabic",
  [WritingSystemIds.arabic_romanized]: "Arabic Romanized",
  [WritingSystemIds.pinyin]: "Pinyin",
  [WritingSystemIds.hanzi]: "Hanzi",
  [WritingSystemIds.french]: "French",
  [WritingSystemIds.german]: "German",
  [WritingSystemIds.hindi]: "Devanagari",
  [WritingSystemIds.hindi_romanized]: "Hindi Romanized",
  [WritingSystemIds.italian]: "Italian",
  [WritingSystemIds.korean]: "Hangeul",
  [WritingSystemIds.korean_romanized]: "Korean Romanized",
  [WritingSystemIds.portuguese]: "Portuguese",
  [WritingSystemIds.esl]: "ESL",
  [WritingSystemIds.russian_romanized]: "Russian Romanized",
  [WritingSystemIds.russian]: "Cyrillic",
  21: "English Literal",
  32: "Traditional Chinese",
} satisfies Record<number, string>;

/**
 * Languages with scripted writing systems
 * Used for things like Romanization toggle and Sort It
 */
export const isScriptLanguage: { [key: string]: boolean | undefined } = {
  japanese: true,
  chinese: true,
  arabic: true,
  hindi: true,
  korean: true,
  russian: true,
};

export const RomanizedWritingSystemIds = [
  WritingSystemIds.romaji,
  WritingSystemIds.pinyin,
  WritingSystemIds.arabic_romanized,
  WritingSystemIds.hindi_romanized,
  WritingSystemIds.korean_romanized,
  WritingSystemIds.russian_romanized,
];

export const ScriptWritingSystemIds = [
  WritingSystemIds.arabic,
  WritingSystemIds.hanzi,
  WritingSystemIds.hindi,
  WritingSystemIds.kanji,
  WritingSystemIds.hiragana_katakana,
  WritingSystemIds.korean,
  WritingSystemIds.russian,
];

// Used in LessonScreen, LessonItem components.
export const RateableTestIconColors = [
  "#9AC6D6",
  "#ef233c", // red
  "#FFB84D", // amber
  "#3CBE68", // green
] satisfies [string, string, string, string];

export const genericErrorText = "Sorry! We've run into an error. Please try again.";

export const DictionaryTopicTypeIdMap: { [key: number]: string } = {
  1: "Numbers and Measurement",
  2: "Colors and Shapes",
  3: "Work and Jobs",
  4: "Food and Drink",
  5: "Clothes and Accessories",
  6: "Shopping",
  7: "Family and Friends",
  8: "Education",
  9: "Health, Medicine and Exercise",
  10: "Hobbies and Leisure",
  11: "Entertainment and Media",
  13: "Communication and Technology",
  14: "Religion",
  16: "The Natural World",
  17: "Calendar and Time",
  18: "Equipment and Stationary",
  19: "Travel and Transport",
  20: "Alphabet",
  21: "Names",
  22: "Common Words",
  23: "House and Home",
  24: "Emotions and Experience",
  25: "Places",
  26: "Sport",
  27: "Weather",
};

export const MultiuserAccountTypeMap: { [key in MultiuserAccountTypeSlug]: { name: string; id: number } } = {
  commercial: { name: "Commercial", id: 1 },
  library: { name: "Library", id: 2 },
  mackin: { name: "Mackin", id: 3 },
};

export const MultiuserAccountStatusMap: { [key in MultiuserAccountStatusSlug]: MultiuserAccountStatusName } = {
  active: "Active",
  demo: "Demo",
  inactive: "Inactive",
};

export const LessonTypeCodes: LessonTypeCode[] = [
  "interactive-audio-course",
  "survival-kit",
  "language-and-culture",
  "travelogue",
  "sign-language-lessons",
  "video-lesson",
  "esl-conversations-vocab",
  "esl-language-lessons",
  "writing",
  "ebook-chapter",
  "newsletter",
  "onboarding",
  "play-the-part",
];

export const LessonTypeLabels: { [key in LessonTypeCode]: string } = {
  "interactive-audio-course": "Audio Lesson",
  "survival-kit": "Survival Kit",
  "language-and-culture": "Language & Culture Lesson",
  travelogue: "Travelogues",
  "sign-language-lessons": "Sign Language Lesson",
  "video-lesson": "Video Lesson",
  "esl-conversations-vocab": "ESL Conversations Lesson",
  "esl-language-lessons": "ESL Language Lesson",
  writing: "Writing Lesson",
  "ebook-chapter": "Learn a Language Extraordinarily Fast",
  newsletter: "Free Resource",
  onboarding: "Onboarding",
  "play-the-part": "Play the Part",
};

/** Lesson types, mapped to their IDs */
export const LessonTypeId = {
  IAC: 1,
  LANGUAGE_AND_CULTURE: 2,
  TRAVELOGUE: 43,
};

export const LessonCodesByTypeId: { [key: number]: LessonTypeCode } = {
  1: "interactive-audio-course",
  2: "language-and-culture",
  3: "survival-kit",
  5: "newsletter",
  6: "writing",
  7: "video-lesson",
  9: "esl-conversations-vocab",
  10: "esl-language-lessons",
  42: "sign-language-lessons",
  43: "travelogue",
  47: "ebook-chapter",
  48: "onboarding",
  49: "play-the-part",
};

export const LessonTypesByCode: { [key in LessonTypeCode]: number } = {
  "interactive-audio-course": 1,
  "language-and-culture": 2,
  "survival-kit": 3,
  newsletter: 5,
  writing: 6,
  "video-lesson": 7,
  "esl-conversations-vocab": 9,
  "esl-language-lessons": 10,
  "sign-language-lessons": 42,
  travelogue: 43,
  "ebook-chapter": 47,
  onboarding: 48,
  "play-the-part": 49,
};

export const PermissionsIdsBySlug: { [key in PermissionTypes]: number } = {
  access_members_area: 1,
  post_in_forums: 2,
  access_admin_area: 3,
  manage_roles: 4,
  assign_roles: 5,
  moderate_forums: 6,
  moderate_reviews: 7,
  edit_content: 8,
  edit_users: 9,
  manage_one_multiuser_account: 10,
  manage_all_multiuser_accounts: 11,
  add_multiuser_products: 12,
  manage_core: 13,
  edit_sign_language: 14,
  access_affiliate_area: 15,
  only_view_draft_lessons: 16,
  manage_affiliates: 17,
  // manage_finance: 18,
  view_draft_components: 18,
};

export const supportedImageFormats = new Set(["image/jpeg", "image/png"]);
