import { detect } from "detect-browser";
import { memoize } from "./memoize";

export const detected = detect();

export const isLegacySafariDesktop = (() => {
  if (!detected) {
    return false;
  }
  const { os, name, version } = detected;
  const isOldVersion = !version || parseFloat(version) < 16;
  // NOTE: Safari 15 VR results are quite bad & partial/final results double up -- https://developer.apple.com/forums/thread/694847
  return os === "Mac OS" && name === "safari" && isOldVersion;
})();

export const isSafari = detected?.os === "Mac OS" && detected.name === "safari";
export const isChromeiOS = detected?.name === "crios";

export const isMobileDevice = memoize(
  () => {
    return (
      (typeof window !== "undefined" && typeof window.orientation !== "undefined") ||
      (typeof navigator !== "undefined" && navigator.userAgent.indexOf("IEMobile") !== -1)
    );
  },
  { maxSize: 1 },
);

export const isAndroidMobile = memoize(
  () => {
    const os = detect()?.os;
    return (os || "").toLowerCase().includes("android");
  },
  { maxSize: 1 },
);
