import { type ActionType, action } from "typesafe-actions";
import { useEffect } from "react";
import { create } from "zustand";
import { redux } from "zustand/middleware";
import API from "../../../../../res/Api";
import { type RatedPhrase } from "../../../../../hooks/useRocketRecord/types";
import { useSharedDispatch } from "../../../../../store";
import useCurrentRef from "../../../../../hooks/useCurrentRef";
import { ratePlayIt, requestAddPoints } from "../../../../../store/lesson/actions";
import { isSafari } from "../../../../../utils/browser";

export type PlayItState = {
  /** Game state */
  status: "init" | "countdown" | "active" | "aborted" | "finished";
  /** Whether user is being tested or playing through recordings */
  mode: "test" | "playthrough" | "listen";
  format: "video" | "audio";
  /** Visual overlay indicator before game starts */
  secondsCounter: number;
  /** Current phrase in index. Checks against "state.numLines" to determine completion. */
  index: number;
  /** Number of transcript lines */
  numLines: number;
  /** Active transcript component ID */
  transcriptId: number;
  /** Active character */
  characterId: number;
  /** Track of ratings per line */
  ratingsPerLine: Map<number, { phraseId: number; result?: RatedPhrase; blobUrl?: string }>; // <phraseId, rating>
};

const initialState: PlayItState = {
  status: "init",
  mode: "test",
  format: "audio",
  secondsCounter: 0,
  index: 0,
  numLines: 0,
  characterId: 0,
  transcriptId: 0,
  ratingsPerLine: new Map(),
};

/** The PlayIt global store contains global state needed to inform other components of it's state */
export const useGlobalPlayItStore = create(redux<PlayItState, PlayItActions>(reducer, initialState));

export const usePlayItAnalytics = (options: { transcriptId: number }) => {
  usePlayItEvents({
    onStart({ transcriptId, format, mode, characterId }) {
      if (options.transcriptId !== transcriptId) {
        return;
      }
      const properties =
        mode === "test"
          ? {
              transcriptId,
              format,
              mode,
              characterId,
            }
          : {
              transcriptId,
              format,
              mode,
            };
      API.post("v2/events/capture", {
        event: "roleplay start",
        properties: properties,
      });
    },
    onEnd({ status, transcriptId, format, mode, characterId }) {
      if (options.transcriptId !== transcriptId || status !== "finished") {
        return;
      }

      const properties =
        mode === "test"
          ? {
              transcriptId,
              format,
              mode,
              characterId,
            }
          : {
              transcriptId,
              format,
              mode,
            };

      API.post("v2/events/capture", {
        event: "roleplay end",
        properties: properties,
      });
    },
  });
};

/** Subscribe to events global store */
export const usePlayItEvents = (events: {
  onStart?: (state: PlayItState) => void;
  onEnd?: (state: PlayItState) => void;
}) => {
  // Set event functions as refs to avoid re-subscribing to events
  const onStart = useCurrentRef(events.onStart);
  const onEnd = useCurrentRef(events.onEnd);

  useEffect(() => {
    return useGlobalPlayItStore.subscribe((nextState, currentState) => {
      if (currentState.status === nextState.status) {
        return;
      }

      if (nextState.status === "active") {
        onStart.current?.(nextState);
      }

      if (nextState.status === "finished" || nextState.status === "aborted") {
        onEnd.current?.(nextState);
      }
    });
  }, [onStart, onEnd]);
};

/** Rates PlayIt and adds points to the user */
export const usePlayItRewarder = ({ lessonId, transcriptId }: { lessonId: number; transcriptId: number }) => {
  const dispatch = useSharedDispatch();

  usePlayItEvents({
    onEnd(state) {
      if (state.mode !== "test" || state.transcriptId !== transcriptId) {
        return;
      }

      // Calculate average rating
      const averageRatingPercent =
        Math.ceil(
          Array.from(state.ratingsPerLine).reduce(
            (acc, [, ratedPhrase]) => acc + (ratedPhrase.result?.percentageDisplay || 0),
            0,
          ) / state.ratingsPerLine.size,
        ) || 0;

      if (averageRatingPercent === 0) {
        // User may have aborted the game
        return;
      }

      // Update store & send API requests to rate playit
      dispatch(ratePlayIt(lessonId, state.transcriptId, state.characterId, averageRatingPercent));
      dispatch(
        requestAddPoints({
          data: { lesson: lessonId },
          rewardType: "playItComplete",
        }),
      );
    },
  });
};

/**
 * Actions used to modify the PlayIt state
 */
export const actions = {
  start(payload: { transcriptId: number; characterId: number; numLines: number; format: "video" | "audio" }) {
    return action("START", payload);
  },
  listenConversation(payload: { transcriptId: number; numLines: number; format: "video" | "audio" }) {
    return action("LISTEN_CONVERSATION", payload);
  },
  playbackConversation(payload: { transcriptId: number }) {
    return action("PLAYBACK_CONVERSATION", payload);
  },
  abort() {
    return action("ABORT");
  },
  tick() {
    return action("TICK");
  },
  recordFinish({
    phraseId,
    blobUrl,
    result = {
      ratingLevel: 0,
      percentage: 0,
      percentageDisplay: 0,
      diffResult: [],
    },
  }: {
    phraseId: number;
    blobUrl?: string;
    result?: RatedPhrase;
  }) {
    return action("RECORD_FINISH", { phraseId, result, blobUrl });
  },
  next() {
    return action("NEXT");
  },
  reset() {
    return action("RESET");
  },
};

export type PlayItActions = ActionType<typeof actions>;

function reducer(state: PlayItState, action: PlayItActions): PlayItState {
  switch (action.type) {
    case "START":
      return {
        ...state,
        status: isSafari ? "active" : "countdown",
        mode: "test",
        secondsCounter: isSafari ? 0 : 3,
        ratingsPerLine: new Map(),
        index: 0,
        ...action.payload,
      };
    case "LISTEN_CONVERSATION":
      return {
        ...state,
        ...action.payload,
        status: "active",
        mode: "listen",
        index: 0,
      };
    case "PLAYBACK_CONVERSATION":
      return {
        ...state,
        status: "active",
        mode: "playthrough",
        index: 0,
      };
    case "ABORT":
      return {
        ...state,
        status: "aborted",
        index: 0,
        numLines: 0,
      };
    case "TICK":
      return {
        ...state,
        secondsCounter: state.secondsCounter > 0 ? state.secondsCounter - 1 : 0,
        status: state.secondsCounter <= 1 ? "active" : "countdown",
      };
    case "RECORD_FINISH": {
      const { phraseId } = action.payload;
      const newRatings = new Map(state.ratingsPerLine);
      newRatings.set(phraseId, action.payload);
      return {
        ...state,
        ratingsPerLine: newRatings,
      };
    }
    case "NEXT": {
      const nextIndex = state.index + 1;
      const isFinished = nextIndex >= state.numLines;
      if (isFinished) {
        return {
          ...state,
          status: "finished",
          index: 0,
        };
      }
      return {
        ...state,
        index: nextIndex,
      };
    }
    case "RESET":
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
