import { type ReactNode, createContext } from "react";
import { useState } from "react";

type RateableTestUIContextValue = {
  instructions?: ReactNode;
  instructionsVisible: boolean;
  toggleInstructions(): void;
  settings?: ReactNode;
  settingsVisible: boolean;
  toggleSettings(): void;
};

/** Allows TestUI.Header to access state of TestUI.Container */
export const RateableTestUIContext = createContext<RateableTestUIContextValue | undefined>(undefined);

interface RateableTestUIContextProviderProps {
  children: ReactNode;
  instructions?: React.ReactNode;
  settings?: React.ReactNode;
  rateableTestTypeId?: number;
}

export default function RateableTestUIContextProvider({
  children,
  instructions,
  settings,
}: RateableTestUIContextProviderProps) {
  const [instructionsVisible, setInstructionsVisible] = useState(false);
  const [settingsVisible, setSettingsVisible] = useState(false);

  const toggleInstructions = () => {
    if (!instructions) return;
    setInstructionsVisible((currentState) => !currentState);
  };

  const toggleSettings = () => {
    setSettingsVisible(!settingsVisible);
  };

  return (
    <RateableTestUIContext.Provider
      value={{ instructions, instructionsVisible, toggleInstructions, settings, settingsVisible, toggleSettings }}
    >
      {children}
    </RateableTestUIContext.Provider>
  );
}
