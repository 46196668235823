import { FaEye, FaEyeSlash } from "react-icons/fa";
import { forwardRef, type InputHTMLAttributes, useState } from "react";

import { type IconType } from "react-icons/lib";
import { clsx } from "clsx";
import styles from "./RocketInput.module.scss";

type RocketInputProps = {
  label: string;
  onChange: (arg: React.ChangeEvent<HTMLInputElement>) => void;
  icon?: IconType;
  right?: JSX.Element;
  slimMargins?: boolean;
  helperText?: JSX.Element | string;
  // add an visibility button to click to display text
  password?: boolean;
  bold?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

/** @deprecated */
const RocketInput = forwardRef<HTMLInputElement, RocketInputProps>((props, ref) => {
  const {
    label,
    icon,
    onChange,
    right,
    slimMargins,
    required,
    helperText,
    password,
    bold,
    type: typeProp,
    ...rest
  } = props;
  const [focused, setFocused] = useState(false);
  const defaultType = typeProp || "text";
  const [type, setType] = useState(defaultType);

  const Icon = icon;
  return (
    <div className={clsx(styles.container, slimMargins && styles.slimMargins)}>
      <div className={styles.actionBar}>
        <label
          htmlFor={rest.name}
          className={clsx("mb-1 text-gray-400", focused && styles.focused, bold && styles.bold)}
        >
          {label}
          {required && "*"}
        </label>
        <div>{right}</div>
      </div>
      <div className={styles.rocketInputContainer}>
        <div className={styles.centeredInput}>
          {Icon && <Icon size={24} />}
          <input
            ref={ref}
            id={rest.name || undefined}
            {...rest}
            onChange={onChange}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            required={required}
            type={type}
          />
          {password && defaultType === "password" && (
            <button
              type="button"
              className={clsx(styles.passwordToggle, type !== "password" && styles.passwordToggleActive)}
              onClick={() => {
                if (type === defaultType) {
                  setType("text");
                } else {
                  setType(defaultType);
                }
              }}
            >
              {type === defaultType ? <FaEye size={24} /> : <FaEyeSlash size={24} />}
            </button>
          )}
        </div>
      </div>
      {helperText}
    </div>
  );
});

export default RocketInput;
