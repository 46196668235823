import { useSharedSelector } from "../store";
import { useHasPermission, useHasAnyPermission } from "./useHasPermission";

/** Some users (e.g. multiusers) should never see any call-to-actions (e.g. on courses page) */
export default function useCanSeePurchaseCTA() {
  const isAdmin = useHasPermission("edit_content");
  const isMultiuser = useSharedSelector((s) => s.user.isMultiuser);
  const isMultiuserAdmin = useHasAnyPermission("manage_one_multiuser_account", "manage_all_multiuser_accounts");

  // Staff should always see the CTA
  if (isAdmin) {
    return true;
  }

  return !isMultiuser && !isMultiuserAdmin;
}
