import { BsFillCircleFill } from "react-icons/bs";
import { Button, Centered, CircularProgress, ErrorMessage, PromiseResolver, RoundedButton } from "@rocketlanguages/ui";
import type { Course, RateableTestEntity } from "@rocketlanguages/types";
import { FaArrowLeft, FaChevronLeft, FaChevronRight, FaEdit, FaSync, FaTimes } from "react-icons/fa";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  LessonTypeId,
  type RateableTestLocaleCode,
  RateableTestTypeIds,
  RateableTestTypes,
} from "@rocketlanguages/shared/utils/constants";
import { type ReactNode, Suspense, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import {
  getTestGroups,
  getWriteItWritingSystemName,
  onlyText,
  scrollInstant,
  slugify,
} from "@rocketlanguages/shared/utils";
import { useStoreDispatch, useStoreSelector } from "~/store";
import { NavArrowRight as NavArrowRightIcon, Notes as NotesIcon } from "iconoir-react";
import API from "@rocketlanguages/shared/res/Api";
import { AllTestsComponentMap } from "./includes/testComponents";
import FaceliftPage from "~/components/FaceliftPage";
import { FeedbackModal } from "./includes/FeedbackModal";
import { FiMove } from "react-icons/fi";
import LessonComponents from "~/pages/members/lesson/includes/sections/LessonComponents";
import LessonContext from "@rocketlanguages/shared/context/LessonContext";
import LessonNoteEditor from "~/components/LessonNoteEditor";
import MarkCompleteButton from "@rocketlanguages/shared/components/MarkCompleteButton/MarkCompleteButton";
import PageLoader from "@rocketlanguages/shared/ui/PageLoader/PageLoader";
import { SentryErrorBoundary } from "../../../components/ErrorBoundary";
import StickyNoteIcon from "~/pages/members/lesson/includes/StickyNoteIcon";
import TagNote from "~/components/ui/TagNote/TagNote";
import VocabProvider from "@rocketlanguages/shared/components/Lesson/Vocab/VocabProvider";
import { asyncRequestLesson } from "@rocketlanguages/shared/store/lesson/actions";
import classes from "./lesson.module.scss";
import lessonScreenSelector from "./includes/selector";
import { shallowEqual } from "react-redux";
import useActiveCourse from "@rocketlanguages/shared/hooks/useActiveCourse";
import { useActiveMarkdownHeading } from "@rocketlanguages/shared/components/Lesson/MarkdownComponent/includes/useActiveMarkdownHeading";
import useActiveProduct from "@rocketlanguages/shared/hooks/useActiveProduct";
import useCanContactSupport from "@rocketlanguages/shared/hooks/useCanContactSupport";
import useDraggable from "~/pages/members/lesson/includes/useDraggable";
import useGetter from "@rocketlanguages/shared/hooks/useGetter";
import useLessonRateableTests from "@rocketlanguages/shared/hooks/useLessonRateableTests";
import useTranslation from "@rocketlanguages/shared/hooks/useTranslation";
import { useTestProgress } from "@rocketlanguages/shared/hooks/useRateableTest/useRateableTest";
import { visit } from "@rocketlanguages/shared/store/lesson/actions";
import { useHasPermission } from "@rocketlanguages/shared/hooks/useHasPermission";
import { useStoreTyped } from "~/store";
import createPhraseTestSelectors from "@rocketlanguages/shared/hooks/useRateableTest/_selectors/phraseTestSelectors";
import { useNavigate } from "react-router-dom";
import { usePreferenceValue } from "@rocketlanguages/shared/hooks/usePreference";
import { clsx } from "clsx";
import {
  SidebarAnchor,
  SidebarBackButton,
  SidebarDivider,
  SidebarToolsLink,
  StickySidebarContainer,
} from "~/components/ui/SidebarFacelift";
import RateableTestUI from "@rocketlanguages/shared/components/RateableTests/RateableTestUI";
import useElementObserver from "@rocketlanguages/shared/hooks/useElementObserver";
import { memoize } from "@rocketlanguages/shared/utils/memoize";

type SectionTitle = {
  label: string;
  slug: RateableTestLocaleCode;
  rateableTest: RateableTestEntity;
  component: ReactNode;
};

type DirtySectionTitle = {
  label: string;
  slug: "top";
  children: { id: string; label: string }[] | undefined;
};

const useLessonPageTitle = (lessonId: number) => {
  const currentLesson = useStoreSelector((store) => store.lesson.entities.lessons[lessonId]);
  return currentLesson ? `${currentLesson.number} ${currentLesson.name}` : "Loading...";
};

export default function LessonPage() {
  const lessonId = Number(useParams().lessonId);
  const productId = useActiveProduct()?.id;
  const dispatch = useStoreDispatch();
  const screenData = useStoreSelector((store) => lessonScreenSelector(store, { lessonId }), shallowEqual);
  const getScreenData = useGetter(screenData);
  const pageTitle = useLessonPageTitle(lessonId);
  const navigate = useNavigate();

  useEffect(() => {
    // Scroll to top of page on navigate
    // This avoids unusual behavior where user clicks on a link
    // on the dashboard and it retains the scroll position
    // This bug is apparent if the lesson is already loaded
    scrollInstant(0);
  }, []);

  useEffect(() => {
    if (productId) {
      dispatch(visit({ lessonId, productId }));
      API.post(["v2/lessons/{lesson}/view", { lesson: lessonId }], {});
    }
  }, [dispatch, lessonId, productId]);

  const dispatchRequestLesson = useCallback(() => {
    if (productId) {
      dispatch(asyncRequestLesson({ lessonId, productId }));
    }
  }, [dispatch, lessonId, productId]);

  // Request the lesson whenever lesson or product id changes
  useEffect(() => {
    const data = getScreenData();
    if (data.status !== "loaded") {
      dispatchRequestLesson();
    }
  }, [dispatchRequestLesson, getScreenData]);

  return (
    <FaceliftPage title={pageTitle}>
      <PromiseResolver
        state={screenData}
        loading={
          <Centered>
            <CircularProgress />
          </Centered>
        }
        error={
          <ErrorMessage
            title="Error"
            message={PromiseResolver.getErrorText(screenData)}
            actions={
              <Button type="button" left={<FaArrowLeft />} onClick={() => navigate(-1)}>
                Back to dashboard
              </Button>
            }
          />
        }
      >
        <LoadedLesson />
      </PromiseResolver>
    </FaceliftPage>
  );
}

/** Filters out draft components for regular users */
const useLessonComponents = (lessonId: number) => {
  const lessonComponents = useStoreSelector((store) => store.lesson.entities.lesson_components[lessonId]);
  const debugEnabled = useStoreSelector((state) => state.preferences.debugEnabled);

  const filteredLessonComponents = useMemo(() => {
    if (!lessonComponents) {
      return [];
    }
    if (debugEnabled) {
      return lessonComponents;
    }
    return lessonComponents.filter((_component) => _component.status === "live");
  }, [debugEnabled, lessonComponents]);

  return filteredLessonComponents;
};

function LoadedLesson() {
  const lessonId = Number(useParams().lessonId);
  const store = useStoreTyped();
  const currentLesson = useStoreSelector((store) => store.lesson.entities.lessons[lessonId]);
  const lessonHeadings = useStoreSelector((store) => store.lesson.entities.lesson_headings?.[lessonId]);
  const lessonStatus = useStoreSelector((store) => store.lesson.entities.user_lesson_status[lessonId]);
  const lessonLinks = useStoreSelector((store) => store.lesson.entities.lesson_links[lessonId]);
  const isTravelogue = currentLesson?.lesson_type_id === 43;
  const tags = useStoreSelector((store) => store.lesson.entities.user_lesson_tags[lessonId]) || [];
  const rateableTests = useLessonRateableTests({ lessonId });
  const lessonComponents = useLessonComponents(lessonId);
  const hash = useLocation().hash;
  const activeProduct = useActiveProduct();

  useEffect(() => {
    // when unmounting, unset the active markdown heading
    return () => {
      useActiveMarkdownHeading.setState({
        value: null,
      });
    };
  }, []);

  const rateableTestSections = useRateableTestSections(rateableTests);

  const sectionTitles = useMemo<(SectionTitle | DirtySectionTitle)[]>(() => {
    return [
      {
        label: currentLesson ? `${currentLesson.number || ""} ${currentLesson.name}` : "Lesson content",
        slug: "top",
        children: lessonHeadings,
      },
      ...rateableTestSections,
    ] as (SectionTitle | DirtySectionTitle)[];
  }, [currentLesson, lessonHeadings, rateableTestSections]);

  const sectionIndex = useMemo(() => {
    // Remove "#" from hash
    const hashValue = hash?.substring(1);
    const index = sectionTitles.findIndex((section) => section.slug === hashValue);
    return index === -1 ? 0 : index;
  }, [sectionTitles, hash]);

  const isIAC = currentLesson?.lesson_type_id === LessonTypeId.IAC;

  // On mount, scroll to the hash, if it exists
  useLayoutEffect(() => {
    const hashValue = window.location.hash?.substring(1);

    if (hashValue && hashValue !== "top") {
      // Likely a markdown heading
      setTimeout(() => {
        document.getElementById(hashValue)?.scrollIntoView({ behavior: "auto" });
      }, 32);
      return;
    }

    if (isIAC) {
      return;
    }

    // Non-IAC lessons: scroll to the % of the page that the user has viewed
    const userLessonProgress = store.getState().lesson.entities.user_lesson_progress[lessonId];

    if (userLessonProgress) {
      // Scroll to the percentage of the lesson that the user has completed
      setTimeout(() => {
        const lessonContentHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const scrollPosition = (userLessonProgress.progress / 100) * lessonContentHeight;
        document.documentElement.scrollTop = scrollPosition;
      }, 32);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const lessonSections = useMemo(() => {
    return [
      // First section is the lesson content
      () => <LessonComponents components={lessonComponents} />,
      // Other sections are the rateable tests
      ...rateableTestSections.map((section) => section.component),
    ];
  }, [lessonComponents, rateableTestSections]);

  const CurrentSection = lessonSections[sectionIndex];

  const isFirstSection = sectionIndex === 0;
  const isLastSection = sectionIndex === lessonSections.length - 1;
  const testSectionTitles = sectionTitles.filter((section) => section.slug !== "top") as SectionTitle[];

  return (
    <FaceliftPage.TwoColumns>
      <LessonSidebar sections={sectionTitles} activeSectionIndex={sectionIndex} />
      <>
        {tags.map((tag) => (
          <TagNote key={tag.slug} tag={tag} />
        ))}
        <LessonErrorBoundary>
          <VocabProvider>
            <Suspense fallback={<PageLoader />}>
              <LessonContext.Provider
                value={memoizeLessonContextValue({
                  id: lessonId,
                  lesson_type_id: currentLesson?.lesson_type_id || 0,
                  isDone: Boolean(lessonStatus?.is_done),
                  isTravelogue,
                  title: currentLesson?.name || "",
                  number: currentLesson?.number || "",
                  image_url: currentLesson?.image_url || "",
                  iac_image_url: currentLesson?.iac_image_url || "",
                })}
              >
                <div className="flex min-h-[300px] w-full flex-grow flex-col border border-missilestroke bg-surface2 p-2 sm:rounded-2xl sm:p-8 md:min-h-[536px]">
                  {isFirstSection ? (
                    <>
                      <div className="flex w-full flex-row flex-wrap items-center justify-between gap-x-16 gap-y-2 text-nowrap text-xs">
                        <div className="w-full lg:w-auto">
                          {currentLesson?.name ? (
                            <LessonTitle className="text-3xl font-bold">
                              {currentLesson?.number ? `${currentLesson?.number} ` : null}
                              {currentLesson?.name}
                            </LessonTitle>
                          ) : null}
                        </div>
                        {currentLesson?.duration_minutes ? <span>{currentLesson.duration_minutes} min</span> : null}
                      </div>
                    </>
                  ) : null}
                  {CurrentSection ? <CurrentSection /> : null}
                  <AdminEditButton lessonId={lessonId} />
                </div>

                {isFirstSection && testSectionTitles.length > 0 && (
                  <div className="my-6 space-y-8 bg-surface2 p-4 sm:rounded-2xl sm:p-8">
                    <div className="text-lg font-bold leading-6">Activities</div>
                    <div className="space-y-6">
                      {testSectionTitles.map((sectionTitle) => (
                        <TestItem key={sectionTitle.slug} sectionTitle={sectionTitle} lessonId={lessonId} />
                      ))}
                    </div>
                  </div>
                )}

                <div className="my-4 grid grid-cols-2 gap-4 md:mx-0 lg:grid-cols-3">
                  {!isFirstSection ? (
                    <SectionNavButton
                      title={sectionTitles[sectionIndex - 1]?.label || "Previous"}
                      href={`#${sectionTitles[sectionIndex - 1]?.slug || ""}`}
                      direction="back"
                    />
                  ) : null}

                  {rateableTests.length === 0 ? (
                    <div className="max-w-72 lg:col-start-2 lg:justify-self-center">
                      <MarkCompleteButton lessonId={lessonId} />
                    </div>
                  ) : null}

                  <div className="col-start-2 max-w-72 justify-self-end lg:col-start-3">
                    {!(isFirstSection || isLastSection) ? (
                      <SectionNavButton
                        title={sectionTitles[sectionIndex + 1]?.label || "Next"}
                        href={`#${sectionTitles[sectionIndex + 1]?.slug || ""}`}
                        direction="forward"
                      />
                    ) : null}

                    {(isFirstSection || isLastSection) && !!lessonLinks?.next ? (
                      <Link
                        aria-label="Navigate to next lesson"
                        to={`/members/products/${activeProduct?.id}/lesson/${lessonLinks.next}`}
                      >
                        <RoundedButton className="border border-missilestroke bg-surface2 font-semibold hover:bg-missilesurfacelight print:hidden">
                          Next Lesson <NavArrowRightIcon className="size-4" strokeWidth={2} />
                        </RoundedButton>
                      </Link>
                    ) : null}
                  </div>
                </div>
              </LessonContext.Provider>
            </Suspense>
          </VocabProvider>
        </LessonErrorBoundary>
      </>
    </FaceliftPage.TwoColumns>
  );
}

function LessonTitle(props: { children: ReactNode; className?: string }) {
  const id = slugify(onlyText(props.children));

  const ref = useElementObserver<HTMLHeadingElement>(
    (visible) => {
      if (visible) {
        useActiveMarkdownHeading.setState({ value: id });
      }
    },
    { rootMargin: "0px 0px -200px 0px" },
  );

  return (
    <h1 className={props.className} ref={ref} id={id}>
      {props.children}
    </h1>
  );
}

const useRateableTestSections = (rateableTests: RateableTestEntity[]) => {
  const { tests, extraTests } = getTestGroups(rateableTests);
  const course = useActiveCourse();
  const courseSlug = course?.slug || "";
  const t = useTranslation();
  const store = useStoreTyped();
  const lessonId = Number(useParams().lessonId);

  const useableExtraTests = useMemo(() => {
    // don't replace write it if russian
    if (courseSlug === "russian") {
      return extraTests;
    }

    const testsMap: Map<number, RateableTestEntity> = new Map();

    for (const test of extraTests) {
      // Make sure that there's at least one component for the extra sort it test
      // Otherwise, don't show it
      if (test.rateable_test_type_id === RateableTestTypeIds.SORT_IT) {
        const selectors = createPhraseTestSelectors();
        const components = selectors.allComponents({
          lessonId,
          rateableTestId: test.id,
          state: store.getState(),
          testTypeId: test.rateable_test_type_id,
        });
        if (components.length === 0) {
          console.warn("No components found for extra sort it test", test.id);
          continue;
        }
      }

      testsMap.set(test.rateable_test_type_id, test);
    }

    // don't show write it if sort it is present
    if (testsMap.has(RateableTestTypeIds.WRITE_IT) && testsMap.has(RateableTestTypeIds.SORT_IT)) {
      // replace sort it with write it if chinese
      if (courseSlug === "chinese") {
        testsMap.delete(RateableTestTypeIds.SORT_IT);
      } else {
        testsMap.delete(RateableTestTypeIds.WRITE_IT);
      }
    }
    // don't show write it native if sort it native is present
    if (testsMap.has(RateableTestTypeIds.WRITE_IT_NATIVE) && testsMap.has(RateableTestTypeIds.SORT_IT_NATIVE)) {
      testsMap.delete(RateableTestTypeIds.WRITE_IT_NATIVE);
    }
    return [...testsMap.values()];
  }, [courseSlug, extraTests, lessonId, store]);

  return useMemo(() => {
    if (!course) {
      return [];
    }

    // Show Cyrillic SortIt before Cyrillic Write It for Russian
    if (courseSlug === "russian") {
      useableExtraTests.sort(({ rateable_test_type_id: typeId }) => {
        if (([RateableTestTypeIds.SORT_IT_NATIVE, RateableTestTypeIds.SORT_IT] as number[]).includes(typeId)) {
          return -1;
        }
        return 1;
      });
    }

    const delegate = getRateableTestSidebarItem(course, t);

    return [...tests.map(delegate), ...useableExtraTests.map(delegate)];
  }, [course, courseSlug, tests, useableExtraTests, t]);
};

const getRateableTestSidebarItem = (course: Course, t: ReturnType<typeof useTranslation>) => {
  return (test: RateableTestEntity) => {
    const { rateable_test_type_id: typeId } = test;

    const label = (() => {
      const testCode = RateableTestTypes[test.rateable_test_type_id]?.code || "flashcards";
      const testName = t(testCode);

      if (typeId === RateableTestTypeIds.SORT_IT) {
        const wsName = getWriteItWritingSystemName({ course, isNative: false });
        return `${testName} (${wsName})`;
      }

      if (typeId === RateableTestTypeIds.WRITE_IT_NATIVE || typeId === RateableTestTypeIds.SORT_IT_NATIVE) {
        const wsName = getWriteItWritingSystemName({ course, isNative: true });

        return `${testName} (${wsName})`;
      }

      return testName || RateableTestTypes[test.rateable_test_type_id]?.name || "";
    })();

    const TestComponent = AllTestsComponentMap[test.rateable_test_type_id as keyof typeof AllTestsComponentMap];

    return {
      label: test.is_extra ? `Extra: ${label}` : label,
      slug: RateableTestTypes[test.rateable_test_type_id]?.code || "",
      rateableTest: test,
      component: TestComponent
        ? () => <TestComponent key={test.id} rateableTestId={test.id} rateableTestTypeId={test.rateable_test_type_id} />
        : () => <span>Could not find test component for {test.rateable_test_type_id}</span>,
    };
  };
};

function AdminEditButton(props: { lessonId: number }) {
  const canEditContent = useHasPermission("edit_content");
  if (!canEditContent) {
    return null;
  }
  return (
    <div className={classes.editButton}>
      <a
        href={`https://staff.rocketlanguages.com/admin/lessons/${props.lessonId}`}
        target="_blank"
        className="block rounded-full bg-rose-500 p-4 text-white hover:bg-rose-600"
        rel="noopener noreferrer"
        color="primary"
        title="Edit Lesson"
      >
        <FaEdit size={24} />
      </a>
    </div>
  );
}

type SectionNavButtonProps = {
  title: string;
  direction: "back" | "forward";
  href: string;
  className?: string;
};

function SectionNavButton({ href, title, direction, className }: SectionNavButtonProps) {
  return (
    <a
      type="button"
      title={title}
      aria-label={`Navigate ${direction} to ${title}`}
      href={href}
      className={clsx(
        "flex h-12 w-12 items-center justify-center rounded-full bg-surface2 hover:bg-brand2 dark:hover:bg-brand print:hidden",
        className,
      )}
    >
      {direction === "back" ? <FaChevronLeft size={16} /> : <FaChevronRight size={16} />}
    </a>
  );
}

function LessonSidebar({
  sections,
  activeSectionIndex,
}: {
  sections: (SectionTitle | DirtySectionTitle)[];
  activeSectionIndex: number;
}) {
  const t = useTranslation();
  const activeProductId = useActiveProduct()?.id;
  const [notesOpen, setNotesOpen] = useState(false);
  const lessonId = Number(useParams().lessonId);
  const hasNotes = useStoreSelector((store) =>
    Boolean(store.lesson.entities.user_lesson_page_data?.[lessonId || -1]?.note?.text),
  );
  const activeHeading = useActiveMarkdownHeading((s) => s.value);
  const [showFeedback, setShowFeedback] = useState(false);
  const canContactSupport = useCanContactSupport();
  const showNotes = usePreferenceValue("show_notes", "0") === "1";

  return (
    <>
      <div className="relative">{notesOpen && <LessonNotesWindow onClose={() => setNotesOpen(false)} />}</div>
      <StickySidebarContainer className="space-y-4">
        <div className="rounded-2xl border border-missilestroke bg-surface2 p-2">
          <SidebarBackButton to={`/members/products/${activeProductId}/dashboard`} label={t("dashboard")} />
          <SidebarDivider />
          <div className="space-y-1">
            {sections.map((section, index) => {
              const isTopSection = section.slug === "top";
              const isParentSectionActive = (() => {
                if (index !== activeSectionIndex) {
                  return false;
                }
                // If one of the children is active, don't style the parent as active
                if (section.slug === "top" && section.children?.some((c) => activeHeading === c.id)) {
                  return false;
                }
                return true;
              })();

              return (
                <div key={section.label}>
                  <SidebarAnchor
                    href={`#${section.slug}`}
                    onClick={() => {
                      // Scroll to top of page when navigating to a new section
                      window.scrollTo(0, 0);
                    }}
                    label={section.label}
                    active={isParentSectionActive}
                    icon={
                      !isTopSection ? (
                        <RateableTestUI.Icon
                          className="h-8 w-8"
                          iconClassName="h-4 w-4"
                          strokeWidth={2}
                          rateableTest={section.rateableTest}
                        />
                      ) : undefined
                    }
                    className={clsx(isTopSection && "font-bold")}
                  />
                  {isTopSection
                    ? section.children?.map(({ id, label }) => {
                        return (
                          <SidebarAnchor
                            key={id}
                            href={`#${id}`}
                            onClick={() => {
                              // This is necessary because there may be two visible headings
                              useActiveMarkdownHeading.setState((s) => ({ ...s, value: id }));
                            }}
                            label={label}
                            active={index === activeSectionIndex && activeHeading === id}
                            className="hidden md:flex"
                          />
                        );
                      })
                    : null}
                </div>
              );
            })}
          </div>
        </div>
        <div className="rounded-2xl border border-missilestroke bg-surface2 p-2">
          <SidebarToolsLink />
          {showNotes && (
            <button
              type="button"
              className="flex h-12 w-full cursor-pointer items-center gap-2 rounded-3xl px-5 hover:bg-missilestroke hover:dark:bg-neutral-700"
              onClick={() => setNotesOpen(!notesOpen)}
            >
              <div className="flex h-8 w-8 items-center justify-center rounded-full border border-neutral-500/10 bg-missilestroke text-missilegray2 dark:text-white">
                <NotesIcon aria-hidden className="h-4 w-4" strokeWidth={2} />
              </div>
              <span>{t("notes")}</span>
              {/** Add star if user has saved notes */}
              {hasNotes && <BsFillCircleFill className="text-missileaccent" size={10} />}
            </button>
          )}
        </div>
        {canContactSupport && (
          <div className="px-8">
            <button
              type="button"
              className="cursor-pointer text-sm text-missilegray hover:underline dark:text-missiledark"
              onClick={() => setShowFeedback(true)}
            >
              {t("got-feedback")}
            </button>
            <FeedbackModal isOpen={showFeedback} setIsOpen={setShowFeedback} lessonId={lessonId} />
          </div>
        )}
      </StickySidebarContainer>
    </>
  );
}

function LessonNotesWindow(props: { onClose: () => void }) {
  const lessonId = Number(useParams().lessonId);
  const styleNode = useRef<HTMLDivElement>(null);
  const { target } = useDraggable<HTMLDivElement>({ styleNode });
  const pageData = useStoreSelector((store) => store.lesson.entities.user_lesson_page_data?.[lessonId || -1]);
  const [saving, setSaving] = useState(false);
  const t = useTranslation();

  return (
    <div className="fixed z-50" ref={styleNode}>
      <div className="flex min-w-[280px] flex-col overflow-hidden rounded-md border-2 border-brand bg-surface2 p-4 shadow-lg">
        <div className="mb-4 flex justify-between">
          <div className="flex items-center">
            <div className="mr-2 text-missilebrand dark:text-missiledark" aria-hidden>
              <StickyNoteIcon />
            </div>
            <h3 className="font-sans">{t("notes")}</h3>
          </div>
          <div className="flex items-center gap-4">
            {saving && (
              <div aria-busy className={"flex items-center gap-2"}>
                <div aria-hidden>
                  <FaSync className="animate-spin" />
                </div>
                <p>{t("saving")}...</p>
              </div>
            )}
            <div ref={target} className="flex items-center">
              <button title="Move" type="button" aria-label="Move window">
                <FiMove size={24} />
              </button>
            </div>
            <button onClick={props.onClose} type="button" title="Close" aria-label="Close window">
              <FaTimes size={24} />
            </button>
          </div>
        </div>
        <LessonNoteEditor
          note={pageData?.note || null}
          lessonId={lessonId}
          onSaving={setSaving}
          onClose={props.onClose}
        />
      </div>
    </div>
  );
}

const memoizeLessonContextValue = memoize(
  (params: {
    id: number;
    lesson_type_id: number;
    isDone: boolean;
    isTravelogue: boolean;
    title: string;
    number: string;
    image_url: string;
    iac_image_url: string | null;
  }) => {
    const { id, isDone, isTravelogue, lesson_type_id, title, number, image_url, iac_image_url } = params;
    return {
      id,
      isDone,
      lesson_type_id,
      isTravelogue,
      title,
      number,
      image_url,
      iac_image_url,
    };
  },
  {
    maxSize: 1,
    comparisonFunction: ([prev], [next]) => shallowEqual(prev, next),
  },
);

function LessonErrorBoundary(props: { children: ReactNode }) {
  return (
    <SentryErrorBoundary
      fallback={
        <ErrorMessage
          title=""
          message="An error was encountered on this page. Please try reloading the page."
          actions={
            <Button color="primary" onClick={() => window.location.reload()}>
              Reload
            </Button>
          }
        />
      }
    >
      {props.children}
    </SentryErrorBoundary>
  );
}

function TestItem({ sectionTitle, lessonId }: { sectionTitle: SectionTitle; lessonId: number }) {
  const progress = useTestProgress(sectionTitle.rateableTest, lessonId);
  const testData = RateableTestTypes[sectionTitle.rateableTest.rateable_test_type_id];

  if (!testData) {
    return null;
  }

  const isCompleted = progress.isComplete || progress.numRated >= progress.totalComponents;
  const numEasyPhrases = progress.numRated - progress.numNonEasy;
  const numHardPhrases = progress.numNonEasy;

  const numPhrasesLeft = progress.hasStarted
    ? progress.totalComponents - numHardPhrases - numEasyPhrases
    : progress.totalComponents;

  return (
    <a
      className="flex w-full cursor-pointer flex-row space-x-4 rounded-2xl border border-missilestroke px-6 py-4 hover:bg-missilesurfacelight"
      key={`sectionTitle.${sectionTitle.slug}`}
      href={`#${sectionTitle.slug}`}
    >
      <RateableTestUI.Icon rateableTest={sectionTitle.rateableTest} />
      <div className="flex w-full flex-col justify-center space-y-2">
        <div className="space-y-1">
          <div className="font-bold">{sectionTitle.label}</div>
          <div>{testData.description}</div>
        </div>

        <div className="h-1 border-t border-missilestroke" />
        <div className="flex w-full justify-between">
          {progress.hasStarted ? (
            <div className="flex flex-row space-x-1">
              <div
                className={clsx(
                  "w-8 rounded-lg px-2 py-1 text-center text-sm font-semibold leading-3",
                  numEasyPhrases === 0 ? "bg-missilesurfacedark" : "bg-missilegreen text-white",
                )}
              >
                {numEasyPhrases}
              </div>
              <div
                className={clsx(
                  "w-8 rounded-lg px-2 py-1 text-center text-sm font-semibold leading-3",
                  numHardPhrases === 0 ? "bg-missilesurfacedark" : "bg-missileaccent text-white",
                )}
              >
                {numHardPhrases}
              </div>
            </div>
          ) : (
            <div />
          )}
          {!isCompleted && numPhrasesLeft !== 0 ? (
            <div className="rounded-lg bg-missiledark px-2 py-1 text-sm font-semibold leading-3 text-white dark:bg-missilesurfacedark">
              {numPhrasesLeft} left
            </div>
          ) : null}
        </div>
      </div>
    </a>
  );
}
