import { type ComponentTypeIds, getRatingValueFromLabel, getUpdatedUserComponentRatings } from "./utils";
import { asyncResetTest, rateTest, updateComponentRatings } from "../../store/lesson/actions";
import { create } from "zustand";
import type { StoreApi, UseBoundStore } from "zustand";

import API from "../../res/Api";
import type { RateableTestEntity, RateableTestMode } from "@rocketlanguages/types";
import { RateableTestIconColors, RateableTestTypeIds } from "../../utils/constants";
import type { SharedRootAction, SharedRootState } from "../../store/types";
import type { RateableTestSelectors } from "./types";
import type { Store } from "redux";
import { shallowEqual } from "react-redux";
import { useMemo } from "react";
import { useSharedSelector, useSharedStore } from "../../store";
import { useRateableTestRatingLevel } from "../useLessonRateableTests";
import useRateableTestSelectors from "./useRateableTestSelectors";

export type RateableTestActions = {
  /** Starts the test */
  start(): void;
  /** Reveals */
  reveal(suggestedRatingLevel?: number): void;
  /** Updates "suggestedRating" */
  setSuggestedRatingLevel(ratingLevel: number): void;
  /** Redo */
  redoNonEasyCharacters(): void;
  /** Resets the test */
  reset(): void;
  rate(label: "easy" | "good" | "hard"): void;
};

export type RateableTestState<TComponentType> = {
  /** Current index. Use for accessing testComponents[index] */
  index: number;
  /** Current session status */
  status: "idle" | "active" | "complete";
  /** Whether the current item is revealed */
  revealed: boolean;
  /** Suggested rating level */
  suggestedRating: number | undefined;
  /** Whether the user has completed the test in the current session */
  hasCompletedWithinSession: boolean;
  /** All components involved in testing */
  allComponents: TComponentType[];
  /** Shuffled list of incomplete components to be tested on */
  testComponents: TComponentType[];
  actions: RateableTestActions;
  /** Metadata */
  meta: {
    rateableTestId: number;
    componentType: keyof typeof ComponentTypeIds;
  };
};

interface UseRateableTestProps<TComponentType extends { id: number }> {
  initialStatus: "idle" | "active" | "complete";
  rateableTestId: number;
  testTypeId: number;
  lessonId: number;
  mode: RateableTestMode;
  selectors: RateableTestSelectors<TComponentType>;
}

/** State manager for any rateable test */
export default function useRateableTest<TComponentType extends { id: number }>({
  initialStatus,
  rateableTestId,
  testTypeId,
  lessonId,
  mode,
  selectors,
}: UseRateableTestProps<TComponentType>) {
  const store = useSharedStore();

  const rateableTestStore = useMemo(
    () =>
      createRateableTestStore({
        initialStatus,
        testTypeId,
        selectors,
        lessonId,
        store,
        rateableTestId,
        mode,
      }),
    [initialStatus, lessonId, testTypeId, store, rateableTestId, mode, selectors],
  );

  return rateableTestStore;
}

export function useRateableTestPosition<T>(useTestStore: UseBoundStore<StoreApi<RateableTestState<T>>>) {
  const { status, index, allComponents, testComponents } = useTestStore(
    ({ status, testComponents, index, allComponents }) => ({ status, index, allComponents, testComponents }),
    shallowEqual,
  );

  const position = (() => {
    switch (status) {
      case "complete":
        return allComponents.length;
      case "active":
        return Math.min(index + (allComponents.length - testComponents.length) + 1, allComponents.length);
      case "idle":
        return Math.min(index + (allComponents.length - testComponents.length) + 1, allComponents.length);
      default:
        return 0;
    }
  })();

  return position;
}

const rateableComponentTypes: Record<number | "default", keyof typeof ComponentTypeIds> = {
  [RateableTestTypeIds.CUSTOM_FLASHCARDS]: "custom_flashcard",
  [RateableTestTypeIds.TRANSLATE_IT]: "video",
  [RateableTestTypeIds.DRAW_IT]: "script_writer",
  default: "phrase",
};

interface CreateRateableTestStoreProps<TComponentType extends { id: number }> {
  store: Store<SharedRootState, SharedRootAction>;
  rateableTestId: number;
  testTypeId: number;
  lessonId: number;
  mode: RateableTestMode;
  selectors: RateableTestSelectors<TComponentType>;
  initialStatus: "idle" | "active" | "complete";
}

function createRateableTestStore<TComponentType extends { id: number }>({
  store,
  rateableTestId,
  testTypeId,
  lessonId,
  mode,
  selectors,
  initialStatus,
}: CreateRateableTestStoreProps<TComponentType>) {
  /** Get the initial state */
  const getInitialState = (mode: RateableTestMode): Omit<RateableTestState<TComponentType>, "actions" | "status"> => {
    const state = store.getState();
    const allComponents = selectors.allComponents({ state, lessonId, rateableTestId, testTypeId }) || [];
    const testComponentsSelector =
      mode === "unrated_components" ? selectors.unratedComponents : selectors.nonEasyComponents;

    const testComponents = testComponentsSelector({ state, lessonId, rateableTestId, components: allComponents });
    // Sort by defined sorting function (else, just shuffle)
    const sort = selectors.sort ? createSorter(selectors.sort) : shuffle;

    return {
      index: 0,
      hasCompletedWithinSession: false,
      allComponents,
      revealed: false,
      suggestedRating: undefined,
      testComponents: sort(testComponents),
      meta: {
        componentType: rateableComponentTypes[testTypeId] || rateableComponentTypes.default,
        rateableTestId,
      },
    };
  };

  const isComplete = isCompleteSelector(store.getState(), rateableTestId);

  return create<RateableTestState<TComponentType>>((set, get) => ({
    ...getInitialState(mode),
    status: initialStatus || (isComplete ? "complete" : "idle"),
    actions: {
      reveal(suggestedRating) {
        set({
          revealed: true,
          suggestedRating,
        });
      },
      setSuggestedRatingLevel(suggestedRating) {
        set({
          suggestedRating,
        });
      },
      start() {
        set({
          status: "active",
          index: 0,
        });
      },
      reset() {
        // Redux store: reset test
        store.dispatch(
          asyncResetTest({
            rateableTestId,
            lessonId,
          }),
        );
        // Reset state
        set({
          ...getInitialState("unrated_components"),
          status: "active",
        });
      },
      redoNonEasyCharacters() {
        set({
          ...getInitialState("non_easy_components"),
          status: "active",
        });
      },
      rate(label: "easy" | "good" | "hard") {
        const currentState = get();
        const currentComponent = currentState.testComponents[currentState.index];
        const nextIndex = currentState.index + 1;
        const isComplete = nextIndex === currentState.testComponents.length;

        // Go to the next item
        set({
          index: nextIndex,
          revealed: false,
          status: isComplete ? "complete" : "active",
          suggestedRating: undefined,
          hasCompletedWithinSession: isComplete,
        });

        const ratingValue = getRatingValueFromLabel(label);

        // Get updated component ratings
        const componentRatings = getUpdatedUserComponentRatings({
          store: store.getState(),
          componentId: currentComponent?.id || -1,
          componentType: get().meta.componentType,
          rateableTestId,
          rating: ratingValue,
        });

        // Update user_rateable_test_component_ratings to rate the phrase (state.ratings.hard, etc)
        store.dispatch(
          updateComponentRatings({
            rateableTestId,
            componentRatings,
          }),
        );

        // Call API with component & test rating
        const testRating = (() => {
          if (!isComplete) {
            return;
          }
          return componentRatings.reduce((prev, curr) => prev + curr.value, 0) / componentRatings.length;
        })();

        const productId = store.getState().preferences.activeProduct?.id || 0;

        const testRatingData: { productId?: number; testRating?: number } = testRating
          ? {
              productId,
              testRating,
            }
          : {
              // Required for clearing dashboard test progress cache
              productId,
            };

        API.get(
          [
            "v2/rate/component/{rateableComponentType:name}/{componentId}/{rateableTest}/{value}",
            {
              "rateableComponentType:name": get().meta.componentType,
              componentId: currentComponent?.id || -1,
              rateableTest: rateableTestId,
              value: ratingValue,
            },
          ],
          testRatingData,
        ).catch((err) => {
          // TODO: undo "updateComponentRatings" / "rateTest" actions
          console.warn(err);
        });

        // Client side update of rating level
        if (testRating) {
          store.dispatch(
            rateTest({
              rateableTestId,
              rating: testRating,
            }),
          );
          /*
           Custom flashcards
             dispatchStore(
              rateCurrentTest({
                collectionId,
                totalRating: testRating,
              }),
            );
          */
        }
      },
    },
  }));
}

const isCompleteSelector = (state: SharedRootState, rateableTestId: number) => {
  const rateableTestRating = state.lesson.entities.user_rateable_test_ratings[rateableTestId];
  return Boolean(rateableTestRating?.value && !rateableTestRating.marked_complete);
};

function shuffle<T>(array: T[]) {
  return array.sort(() => Math.random() - 0.5);
}

function createSorter<T>(getValue: (item: T) => number) {
  return (arr: T[]) =>
    arr.sort((a: T, b: T) => {
      const foo = getValue(a);
      const bar = getValue(b);
      return foo < bar ? -1 : 1;
    });
}

export function useTestProgress(test: RateableTestEntity, lessonId: number) {
  const ratingLevel = useRateableTestRatingLevel(test.id);

  const numTestComponents = useNumTestComponents({
    rateableTestId: test.id,
    rateableTestTypeId: test.rateable_test_type_id,
    lessonId,
  });

  return {
    isResetting: Boolean(useSharedSelector((state) => state.lesson.testResetting[test.id])),
    ...numTestComponents,
    ratingLevel,
    isComplete: ratingLevel > 0,
    hasStarted: numTestComponents.numRated > 0,
    color: RateableTestIconColors[ratingLevel],
  };
}

export function useNumTestComponents({
  rateableTestId,
  rateableTestTypeId,
  lessonId,
}: {
  rateableTestId: number;
  rateableTestTypeId: number;
  lessonId: number;
}) {
  const selectors = useRateableTestSelectors(rateableTestTypeId);

  return useSharedSelector((state) => {
    if (rateableTestTypeId === RateableTestTypeIds.QUIZ) {
      const quiz = state.lesson.entities.lesson_quiz[lessonId];
      return {
        numRated: 0,
        numNonEasy: 0,
        totalComponents: quiz?.sections?.reduce((acc, curr) => acc + curr.questions.length, 0) || 0,
      };
    }

    const components = selectors.allComponents({ state, lessonId, rateableTestId, testTypeId: rateableTestTypeId });
    return {
      totalComponents: components.length,
      numNonEasy: selectors.nonEasyComponents({ state, lessonId, components, rateableTestId }).length,
      numRated: components.length - selectors.unratedComponents({ state, lessonId, components, rateableTestId }).length,
    };
  }, shallowEqual);
}
