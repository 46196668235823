import * as authActions from "./actions";
import * as constants from "./constants";
import * as preferencesActions from "../preferences/actions";

import { type SagaReturnType, call, put, takeLatest } from "redux-saga/effects";

import API from "../../res/Api";
import { type ActionType } from "typesafe-actions";
import Courses from "../../res/courses";
import { getLaravelValidationError } from "../../utils";
import { history } from "../../res/router";

/**
 * User logs in
 * Triggered from: Login.tsx
 */
function* asyncLoginRequest(action: ReturnType<typeof authActions.asyncLoginRequest>) {
  try {
    // Get csrf cookie
    // yield call(() =>
    //   API.axios.get("/sanctum/csrf-cookie", {
    //     baseURL: process.env.REACT_APP_BASE_URL_DEV || "https://app.rocketlanguages.com",
    //   }),
    // );

    const request = () =>
      API.post("v2/login", {
        ...action.payload,
        source: "web",
        // force_onboarding: 1,
      });

    const response: SagaReturnType<typeof request> = yield call(request);

    // if guest, set the active product to the first trial product so that hitting the courses page redirects to the dashboard
    if (response.data.user.isGuest) {
      // get lowest level course in products
      const productMeta = response.data.user.products.trial[0];
      if (productMeta) {
        const { course_id: courseId } = productMeta;
        const course = Courses.find((_course) => _course.id === courseId);
        if (course) {
          // products in course are ordered by their level, unlike in the response data
          const lowestProduct = course.products.find((_product) =>
            response.data.user.products.trial.some((_trial) => _trial.id === _product.id),
          );
          if (lowestProduct) {
            yield put(preferencesActions.setActiveCourseAndProduct(course, lowestProduct));
          }
        }
      }
    }

    // Successful login, set status to "loggedin"
    yield put(
      authActions.success({
        data: response.data,
        source: "login",
      }),
    );
  } catch (error) {
    yield handleAuthError(error, "login");
  }
}

/**
 * User signs up with a course
 * Triggered from: SignUp.tsx
 */
function* asyncSignupRequest(action: ReturnType<typeof authActions.asyncSignupRequest>) {
  try {
    const course = Courses.find((c) => c.id === action.payload.formData.courseId);

    if (!course) {
      console.warn("Unknown course", course);
      return;
    }

    const signupRequest = () =>
      API.post(
        [
          "v2/user/signup-course/{course}/{source}",
          {
            course: course.id,
            source: action.payload.source,
          },
        ],
        action.payload.formData,
      );

    const response: SagaReturnType<typeof signupRequest> = yield call(signupRequest);

    // Browser signups use "relative redirect" urls
    // This should _never_ happen
    if ("relative_redirect_url" in response.data) {
      return;
    }

    // Successful login, set status to "loggedin"
    yield put(
      authActions.success({
        data: response.data,
        source: "signup",
      }),
    );
  } catch (error) {
    console.warn(error);
    yield handleAuthError(error, "signup");
  }
}

/**
 * Library User makes an account
 * Triggered from Library.tsx
 */
function* asyncMultiuserSignupRequest(action: ReturnType<typeof authActions.asyncMultiuserSignupRequest>) {
  try {
    const multiuserSignupRequest = () =>
      API.post(
        ["v2/public/multiuser-account/{account:hash}/register", { "account:hash": action.payload.libraryHash }],
        action.payload.payload,
      );
    const response: SagaReturnType<typeof multiuserSignupRequest> = yield call(multiuserSignupRequest);
    // Successful login, set status to "loggedin"
    yield put(
      authActions.success({
        data: response.data,
        source: "signup",
      }),
    );
  } catch (error) {
    yield handleAuthError(error, "signup");
  }
}

function* asyncGuestSignup(action: ReturnType<typeof authActions.asyncGuestSignup>) {
  try {
    const course = Courses.find((c) => c.id === action.payload.formData.courseId);

    if (!course) {
      console.warn("Unknown course", course);
      throw Error("Unkown course");
    }

    const signupRequest = () =>
      API.post(
        [
          "v2/user/signup-course/{course}/{source}",
          {
            course: course.id,
            source: action.payload.source,
          },
        ],
        action.payload.formData,
      );

    const response: SagaReturnType<typeof signupRequest> = yield call(signupRequest);

    if ("relative_redirect_url" in response.data) {
      history.push(response.data.relative_redirect_url);
    } else {
      yield put(
        authActions.success({
          data: response.data,
          source: "signup",
        }),
      );
    }
  } catch (error: any) {
    console.warn(error);
    let errorMessage = getLaravelValidationError(error);

    if (errorMessage) {
      yield put(authActions.guestAuthFail(errorMessage));
      return;
    }

    if (error.response && error.response.data && error.response.data.error) {
      switch (error.response.data.error) {
        case "validation_error":
          errorMessage = "Unable to validate email or password. Please try again.";
          break;
        case "invalid_credentials":
          errorMessage = "Invalid email or password. Please try again.";
          break;
        default:
          errorMessage = error.response.data.error;
          break;
      }
    } else {
      errorMessage = "There seems to be a problem with the request. Please try again later.";
    }

    yield put(authActions.guestAuthFail(errorMessage!));
  }
}

export function* handleAuthError(error: any, source: "login" | "signup") {
  console.log("[handleAuthError]", Object.keys(error), Object.values(error));

  let errorMessage = getLaravelValidationError(error);

  if (errorMessage) {
    // { identity_number: ["Your Library Card number is required"] }
    yield put(authActions.fail(errorMessage));
    return;
  }

  if (source === "signup") {
    // TODO: check where we get this from
    if (error.response?.data?.error) {
      if (error.response.data.error === "validation_error") {
        const message =
          error.response.data.message ||
          "Unable to validate your email address or password. Please check your input and try again.";
        yield put(authActions.fail(message));
        return;
      }
    }

    yield put(
      authActions.fail("A problem was encountered while trying to sign up to Rocket Languages. Please try again."),
    );
    return;
  }

  if (error.response && error.response.data && error.response.data.error) {
    switch (error.response.data.error) {
      case "validation_error":
        errorMessage = "Unable to validate email or password. Please try again.";
        break;
      case "invalid_credentials":
        errorMessage = "Invalid email or password. Please try again.";
        break;
      default:
        errorMessage = error.response.data.error;
        break;
    }
  } else {
    errorMessage = "There seems to be a problem with the request. Please try again later.";
  }

  yield put(authActions.fail(errorMessage!));
}

function authSuccess(action: ActionType<typeof authActions.success>) {
  // Update default HTTP axios request params with new token
  API.setToken(action.payload.data.auth.token);
}

// Any watchers go in here. They get forked in the Root Saga
export default [
  takeLatest(constants.SUCCESS, authSuccess),
  takeLatest(constants.ASYNC_LOGIN, asyncLoginRequest),
  takeLatest(constants.ASYNC_SIGNUP, asyncSignupRequest),
  takeLatest(constants.ASYNC_MULTIUSER_SIGNUP, asyncMultiuserSignupRequest),
  takeLatest(constants.ASYNC_GUEST_SIGNUP, asyncGuestSignup),
];
// Any watchers go in here. They get forked in the Root Saga
