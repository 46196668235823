import { type Locale, english, russian } from "./locales";

export type KeyMap = {
  [key: string]: string[];
};

export type KeyMapperObject = {
  [key: string]: string;
};

const codes = {
  numbersRow: [
    "Backquote",
    "Digit1",
    "Digit2",
    "Digit3",
    "Digit4",
    "Digit5",
    "Digit6",
    "Digit7",
    "Digit8",
    "Digit9",
    "Digit0",
    "Minus",
    "Equal",
  ],
  tabRow: [
    "KeyQ",
    "KeyW",
    "KeyE",
    "KeyR",
    "KeyT",
    "KeyY",
    "KeyU",
    "KeyI",
    "KeyO",
    "KeyP",
    "BracketLeft",
    "BracketRight",
    "Backslash",
  ],
  capsRow: [
    "CapsLock",
    "KeyA",
    "KeyS",
    "KeyD",
    "KeyF",
    "KeyG",
    "KeyH",
    "KeyJ",
    "KeyK",
    "KeyL",
    "Semicolon",
    "Quote",
    "Enter",
  ],
  shiftRow: ["ShiftLeft", "KeyZ", "KeyX", "KeyC", "KeyV", "KeyB", "KeyN", "KeyM", "Comma", "Period", "Slash"],
} satisfies KeyMap;

const codesKeys = Object.keys(codes);

export const keyCodes = new Map<string, string[]>()
  .set(codesKeys[0] || "", codes.numbersRow)
  .set(codesKeys[1] || "", codes.tabRow)
  .set(codesKeys[2] || "", codes.capsRow)
  .set(codesKeys[3] || "", codes.shiftRow);

export const keyboardLocales: { [key: string]: Locale } = {
  ru: russian,
  default: english,
};
