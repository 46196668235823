import SignLanguageFlag from "./legacy-course-icons/asl.png";
import ArabicFlag from "./legacy-course-icons/arabic.png";
import GermanFlag from "./legacy-course-icons/german.png";
import EnglishFlag from "./legacy-course-icons/english.png";
import SpanishFlag from "./legacy-course-icons/spanish.png";
import FrenchFlag from "./legacy-course-icons/french.png";
import HindiFlag from "./legacy-course-icons/hindi.png";
import ItalianFlag from "./legacy-course-icons/italian.png";
import JapaneseFlag from "./legacy-course-icons/japanese.png";
import KoreanFlag from "./legacy-course-icons/korean.png";
import PortugueseFlag from "./legacy-course-icons/portuguese.png";
import RussianFlag from "./legacy-course-icons/russian.png";
import ChineseFlag from "./legacy-course-icons/chinese.png";
import EbookFlag from "./legacy-course-icons/ebook.png";

const LanguageFlagImages: Record<string, string> = {
  "sign-language": SignLanguageFlag as unknown as string,
  arabic: ArabicFlag as unknown as string,
  "arabic-msa": ArabicFlag as unknown as string,
  german: GermanFlag as unknown as string,
  english: EnglishFlag as unknown as string,
  esl: EnglishFlag as unknown as string,
  ingles: EnglishFlag as unknown as string,
  eigo: EnglishFlag as unknown as string,
  spanish: SpanishFlag as unknown as string,
  french: FrenchFlag as unknown as string,
  hindi: HindiFlag as unknown as string,
  italian: ItalianFlag as unknown as string,
  japanese: JapaneseFlag as unknown as string,
  korean: KoreanFlag as unknown as string,
  portuguese: PortugueseFlag as unknown as string,
  russian: RussianFlag as unknown as string,
  chinese: ChineseFlag as unknown as string,
  ebook: EbookFlag as unknown as string,
};

const LanguageFlagImageLocationsPercentage: Record<string, number> = {
  spanish: 0,
  french: 44,
  "sign-language": 88,
  portuguese: 132,
  korean: 176,
  italian: 220,
  japanese: 264,
  hindi: 308,
  german: 352,
  russian: 396,
  eigo: 440,
  english: 440,
  esl: 440, // ESL is the course slug from our server
  ingles: 440,
  chinese: 484,
  arabic: 528,
  "arabic-msa": 528,
  ebook: 572,
};

export function CourseFlagRect(props: { courseSlug: string }) {
  const placement = LanguageFlagImageLocationsPercentage[props.courseSlug];
  return (
    <div className="relative">
      <div className="min-w-16 max-w-16 overflow-hidden rounded border-[0.5px] border-missilestroke bg-transparent">
        <div
          className="h-11 w-full min-w-16"
          style={{
            backgroundSize: "100% auto",
            backgroundPositionX: "center",
            backgroundPositionY: `-${placement}px`,
            backgroundImage: "url(https://d2jkfj9lazd7el.cloudfront.net/images/front_end/flag-list-rect_v3.png)",
            backgroundRepeat: "no-repeat",
            backgroundColor: "transparent",
          }}
        />
      </div>
    </div>
  );
}

/**
 * This needs a new sprite image because flags like Japanese and Korean look a bit "zoomed" in
 */
export function CourseFlagCircleOld(props: { courseSlug: string; courseName: string }) {
  const placement = LanguageFlagImageLocationsPercentage[props.courseSlug];
  return (
    <div
      // className="h-11 w-11 overflow-hidden bg-white outline outline-1 outline-missilestroke"
      className="size-11 min-h-10 min-w-10 overflow-hidden rounded-full outline outline-1 outline-missilestroke"
      style={{
        backgroundSize: "auto auto",
        backgroundPositionX: "center",
        backgroundPositionY: `-${placement}px`,
        backgroundImage: "url(https://d2jkfj9lazd7el.cloudfront.net/images/front_end/flag-list-rect_v3.png)",
        backgroundRepeat: "no-repeat",
        backgroundColor: "transparent",
      }}
    />
  );
}

export function CourseFlagCircle(props: { courseSlug: string; courseName: string }) {
  const Image = LanguageFlagImages[props.courseSlug];
  // flex size-8 items-center justify-center overflow-hidden rounded-full
  return (
    <div className="size-11 min-h-11 min-w-11 overflow-hidden rounded-full bg-white outline outline-1 outline-missilestroke">
      {Image ? <img src={Image} alt={props.courseName} /> : null}
    </div>
  );
}
