import { type HTMLAttributes, type ForwardRefExoticComponent, forwardRef } from "react";
import styles from "./Bar.module.scss";
import { clsx } from "clsx";

export type BarProps = HTMLAttributes<HTMLDivElement> & {
  use?: string | ForwardRefExoticComponent<any>;

  left?: JSX.Element;
  right?: JSX.Element;
  fill?: boolean;
  pressable?: boolean;
  size?: "small" | "large";
  to?: string;
  active?: boolean;
};

function getBarClass(props: BarProps, pressable?: boolean) {
  const { fill, size, active } = props;
  return clsx(
    styles.bar,
    {
      [styles.pressable!]: pressable,
      [styles.fill!]: fill,
      [styles.large!]: size === "large",
      [styles.small!]: size === "small",
      [styles.active!]: active,
    },
    props.className,
  );
}

export const Bar = forwardRef<HTMLDivElement, BarProps>((props: BarProps, ref) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { use: T = "div", left, right, children, active, fill, ...rest } = props;
  const pressable = Boolean(rest.onClick);

  const style = getBarClass(props, pressable);

  if (pressable) {
    rest.tabIndex = 0;
    rest.role = "button";

    // Add extra onClick handlers (for keyboard shortcuts enter/spacebar when element is focused)
    if (!rest.onKeyPress) {
      rest.onKeyPress = (e: React.KeyboardEvent<any>) => {
        // Enter = 13
        if (rest.onClick && e.nativeEvent.keyCode === 13) {
          // @ts-ignore
          rest.onClick(e);
        }
      };
    }
  }

  return (
    // @ts-ignore
    <T {...rest} ref={ref} className={clsx(style, "text-brand dark:text-white")}>
      {left && <div className={styles.leftContainer}>{left}</div>}
      <div className={styles.titleContainer}>{children}</div>
      {right && <div className={styles.rightContainer}>{right}</div>}
    </T>
  );
});

export default Bar;
