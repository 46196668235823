import { getErrorMessage, noop } from "../utils";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { isSafari } from "../utils/browser";

export type MinifiedAudioState = "init" | "loading" | "playing" | "paused" | "stopped";

function useAudioMinified(audioRef: React.RefObject<HTMLAudioElement | null>, shouldAutoplay = false, speed = 1) {
  const [status, setStatus] = useState<MinifiedAudioState>(shouldAutoplay ? "playing" : "init");

  useEffect(() => {
    const ref = audioRef.current;

    if (ref) {
      let safeSetStatus = setStatus;

      const handleEnded = () => safeSetStatus("stopped");
      const handlePaused = () => safeSetStatus("paused");
      const handlePlaying = () => safeSetStatus("playing");
      const handleLoading = () => safeSetStatus("loading");

      // On Safari 16.1, we want to give visual feedback only when
      // audio is playing. Preload is set to "none" on Safari
      if (isSafari) {
        ref.addEventListener("play", handleLoading);
        ref.addEventListener("playing", handlePlaying);
      } else {
        ref.addEventListener("play", handlePlaying);
      }
      ref.addEventListener("pause", handlePaused);
      ref.addEventListener("ended", handleEnded);

      return () => {
        safeSetStatus = noop;
        ref.removeEventListener("ended", handleEnded);
        ref.removeEventListener("pause", handlePaused);
        if (isSafari) {
          ref.removeEventListener("playing", handlePlaying);
          ref.removeEventListener("play", handleLoading);
        } else {
          ref.removeEventListener("play", handlePlaying);
        }
      };
    }
  }, [audioRef]);

  // Autoplay
  useEffect(() => {
    if (shouldAutoplay) {
      let safeSetSatatus = setStatus;
      const player = audioRef.current;

      const play = () => {
        if (player) {
          // Make sure that we're starting from the beginning
          player.currentTime = 0;
          player.play()?.catch((e) => {
            if (typeof e.message === "string") {
              safeSetSatatus("stopped");
            }
            console.error("Audio error: ", e);
          });
        }
      };

      // On Safari 16.1, there seems to be a bug where the audio never plays
      // when we call play() immediately after mounting the component.
      // The audio element will emit a "play" event, but never a "playing" event.
      // Setting a timeout of 16ms seems to fix this issue.
      if (isSafari) {
        setTimeout(play, 16);
      } else {
        // On other browsers we can just call play() immediately

        // NOTE: this may just fix another issue on other browsers where the start of the audio cuts out
        setTimeout(play, 16);
      }

      return () => {
        safeSetSatatus = noop;
        if (player) {
          player.pause();
          player.currentTime = 0;
        }
      };
    }
  }, [audioRef, shouldAutoplay]);

  return useMemo(
    () => ({
      audioRef,
      status,
      playAudio: (params?: { ignoreToast?: boolean; fromStart: boolean }) => {
        if (audioRef.current) {
          if (params?.fromStart || audioRef.current.currentTime === audioRef.current.duration) {
            audioRef.current.currentTime = 0;
          }
          if (audioRef.current.playbackRate !== speed) {
            audioRef.current.playbackRate = speed;
          }

          const play = () =>
            audioRef.current?.play()?.catch((e) => {
              if (!params?.ignoreToast) {
                toast.error(getErrorMessage(e));
              }
              console.warn(e);
              setStatus("stopped");
            });

          if (isSafari) {
            // This avoids a glitch on Safari where setting `audioRef.current.currentTime = 0` needs awaiting
            // until the timeupdate event is called with 0 as the current time
            // Otherwise the audio will immediately stop if the user replays the audio
            setTimeout(() => {
              play();
            }, 16);
          } else {
            play();
          }
        }
      },
      pauseAudio: () => audioRef.current?.pause(),
      stopAudio: () => {
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
        }
      },
    }),
    [status, audioRef, speed],
  );
}

export default useAudioMinified;
