import { clsx } from "clsx";
import { type InputHTMLAttributes, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

interface FaceliftInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
  type: "email" | "password" | "text" | "number";
}

export default function FaceliftInput(props: FaceliftInputProps) {
  const { name, label, className, type: propsType, ...rest } = props;
  const [type, setType] = useState(propsType);

  return (
    <label className="flex w-full flex-col">
      <span className="mb-1 text-sm font-bold">
        {label}
        {rest.required && <span className="ml-0.5 text-sm text-rocketred">*</span>}
      </span>
      <div className="relative">
        <input
          name={name}
          type={type}
          className={clsx(
            "h-12 w-full rounded border-2 border-brand bg-white px-4 py-2 text-sm focus:bg-surface1 dark:bg-neutral-700 dark:focus:bg-neutral-900",
            className,
          )}
          {...rest}
        />
        {/** Password toggle */}
        {propsType === "password" && (
          <button
            type="button"
            onClick={() => setType((t) => (t === "password" ? "text" : "password"))}
            className="absolute bottom-0 right-0 top-0 px-5 py-2"
          >
            {type === "password" ? <FaEyeSlash size={22} /> : <FaEye size={21} />}
          </button>
        )}
      </div>
    </label>
  );
}
