import Button, { type ButtonProps } from "../Button";
import { forwardRef } from "react";
import type { SquareButtonProps } from "./SquareButton";
import { clsx } from "clsx";
import styles from "./SquareButton.module.scss";

const defaultProps: SquareButtonProps = {
  color: "primary",
  size: "medium",
};

type SquareOutlineButtonProps = Omit<ButtonProps, "left" | "fill" | "color"> & {
  color?: ButtonProps["color"] | "white";
};

const SquareOutlineButton = forwardRef<HTMLButtonElement, SquareOutlineButtonProps>((props, ref) => {
  const { color, ...rest } = props;

  const size = (() => {
    switch (props.size) {
      case "large":
        return "!h-[64px] !w-[64px] !min-w-[64px] !p-0";
      case "medium":
        return "!h-[48px] !w-[48px] !min-w-[48px] !p-0";
      case "small":
        return "!h-[28px] !w-[28px] !min-w-[28px] !p-0";
      default:
        return "!h-[28px] !w-[28px] !min-w-[28px] !p-0";
    }
  })();

  const colors = (() => {
    switch (color) {
      case "primary":
        return "text-brand border-2 border-brand hover:bg-brand";
      case "secondary":
        return "text-rocketblue border-2 border-rocketblue hover:bg-rocketblue";
      case "success":
        return "text-rocketgreen border-2 border-rocketgreen hover:bg-rocketgreen";
      case "warning":
        return "text-rocketorange border-2 border-rocketorange hover:bg-rocketorange";
      case "error":
        return "text-rocketred border-2 border-rocketred hover:bg-rocketred";
      case "white":
        return "text-white border-2 border-white bg-transparent hover:border-white/[.75] hover:text-white/[.75] hover:bg-transparent active:bg-transparent";
      default:
        return "text-brand border-2 border-brand hover:bg-brand";
    }
  })();

  return (
    <Button
      ref={ref}
      {...rest}
      className={clsx(
        "!rounded-md",
        size,
        props.size && !props.loading && styles[props.size],
        colors,
        "bg-transparent hover:text-white",
        props.className,
      )}
    >
      {props.children}
    </Button>
  );
});

SquareOutlineButton.defaultProps = defaultProps;

export default SquareOutlineButton;
