import { type ReactNode } from "react";

interface ErrorMessageProps {
  title: string;
  message?: string;
  actions?: ReactNode;
}

export default function ErrorMessage(props: ErrorMessageProps) {
  return (
    <div className={"flex flex-col gap-4 text-center"}>
      <h2 className="font-sans">{props.title}</h2>
      {props.message && <p>{props.message}</p>}
      {props.actions && <div className={"flex justify-center gap-4"}>{props.actions}</div>}
    </div>
  );
}
