import type { DashboardPayload, LessonEntity, LessonTypeCode, APIResource } from "@rocketlanguages/types";
import { createSelector } from "reselect";
import type { RootState } from "../../../../../store/types";
import { PermissionsIdsBySlug } from "@rocketlanguages/shared/utils/constants";
import { randomString } from "@rocketlanguages/shared/utils/stringUtils";
import { memoize } from "@rocketlanguages/shared/utils/memoize";

export type SectionData = {
  id: string;
  code: LessonTypeCode;
  lessons: LessonEntity[];
};

export type ModuleData = {
  id: number;
  title: string;
  /** Featured modules include body text */
  description?: string | null;
  data: SectionData[];
};

export type DashboardSelectedData = {
  productId: number;
  suggestedLessonId: number;
  sectionTitleCodes: LessonTypeCode[];
  expandedModuleId: number;
  modules: ModuleData[];
};

export type DashboardProps = APIResource<DashboardSelectedData> | undefined;

const lessonsSelector = (state: RootState) => state.lesson.entities.lessons;
const dashboardProductsSelector = (state: RootState) => state.dashboard.products;
const activeProductSelector = (state: RootState) => state.preferences.activeProduct;

/**
 * Gathers and organizes lessons based on the currently active product
 */
export const dashboardSelector = createSelector(
  [
    activeProductSelector,
    dashboardProductsSelector,
    lessonsSelector,
    (state: RootState) => {
      const permissions = state.user.permission_ids || [];
      return {
        canEditContent: permissions.includes(PermissionsIdsBySlug.edit_content),
        canOnlyViewDrafts: permissions.includes(PermissionsIdsBySlug.only_view_draft_lessons),
        debugEnabled: state.preferences.debugEnabled,
      } as const;
    },
  ],
  (activeProduct, products, lessons, permissions): DashboardProps => {
    const activeProductId = activeProduct?.id;
    const product = products[activeProductId || -1];

    if (!activeProductId || !products) {
      return { status: "error", errorText: "No active product or products" };
    }

    if (!product) {
      return;
    }

    if (product.status === "loading" || product.status === "error") {
      return product;
    }
    return dashboardSelectorInner(activeProductId, product, lessons, permissions);
  },
);

/**
 * Main method for gathering dashboard items & rateable tests in to a UI-presentable format
 */
const dashboardSelectorInner = memoize(
  (
    productId: number,
    payload: { status: "loaded"; data: DashboardPayload["dashboard"] },
    allLessons: ReturnType<typeof lessonsSelector>,
    {
      canEditContent,
      canOnlyViewDrafts,
      debugEnabled,
    }: { canEditContent: boolean; canOnlyViewDrafts: boolean; debugEnabled: boolean },
  ): DashboardProps => {
    if (!("modules" in payload.data)) {
      return {
        status: "loaded",
        data: {
          productId: -1,
          suggestedLessonId: 0,
          expandedModuleId: 0,
          modules: [],
          sectionTitleCodes: [],
        },
      };
    }

    const { modules: productModules, suggestedLessonId } = payload.data;

    const modules: ModuleData[] = [];
    const sectionTitleCodes: LessonTypeCode[] = [];

    // First section should be expanded
    let expandedModuleId = 0;

    for (let i = 0; i < productModules.length; i += 1) {
      const subsectionList: SectionData[] = [];
      const productModule = productModules[i];
      if (!productModule) {
        continue;
      }

      for (const { code, lessons: lessonIds } of productModule.grouped_lessons) {
        const lessonList = [];
        for (const lessonId of lessonIds) {
          // Update lesson offset
          if (suggestedLessonId && lessonId === suggestedLessonId) {
            expandedModuleId = productModule.id;
          }

          const lesson = allLessons[lessonId];
          if (!lesson) {
            continue;
          }

          if (canOnlyViewDrafts && !canEditContent && lesson.status !== "draft") {
            continue;
          }

          const canSeeUnpublishedLessons = canEditContent && debugEnabled;
          if (lesson.status !== "published" && !canSeeUnpublishedLessons) {
            continue;
          }

          lessonList.push(lesson);
        }

        if (lessonList.length === 0) {
          continue;
        }

        if (!sectionTitleCodes.some((title) => title === code)) {
          sectionTitleCodes.push(code);
        }

        const id = randomString();

        subsectionList.push({
          id,
          code,
          lessons: lessonList,
        });
      }

      const { title, number: moduleNumber } = productModule;

      modules.push({
        id: productModule.id,
        title: title || `Module ${moduleNumber || i + 1}`,
        description: productModule.description,
        data: subsectionList,
      });
    }

    return {
      status: payload.status,
      data: {
        productId,
        suggestedLessonId,
        expandedModuleId: expandedModuleId || modules[0]?.id || 0,
        modules,
        sectionTitleCodes,
      },
    };
  },
  {
    maxSize: 1,
    comparisonFunction: (a: unknown, b: unknown) => JSON.stringify(a) === JSON.stringify(b),
  },
);
