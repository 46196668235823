import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useState, type InputHTMLAttributes, forwardRef } from "react";

import InputLabel from "../InputLabel";
import classes from "./InputField.module.scss";
import { clsx } from "clsx";

type Props = {
  use?: "input" | "textarea";
  containerClassName?: string;
  label?: string | JSX.Element;
  errorText?: string;
  left?: JSX.Element;
  right?: JSX.Element;
  variant?: "success" | "error" | "password";
} & InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>;

const InputField = forwardRef<HTMLInputElement | HTMLTextAreaElement, Props>((props, ref) => {
  const [focused, setFocused] = useState(false);
  const {
    containerClassName,
    className,
    label,
    errorText,
    left,
    right,
    variant,
    children,
    type: typeProp,
    ...rest
  } = props;
  const [type, setType] = useState(typeProp);

  function onInputFocus(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (props.onFocus) {
      props.onFocus(e);
    }
    setFocused(true);
  }

  function onInputBlur(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (props.onBlur) {
      props.onBlur(e);
    }
    setFocused(false);
  }

  const T: any = props.use || "input";

  const inputElement = (() => {
    const baseProps = {
      className: clsx(classes.input, classes[variant || ""]),
      ...rest,
      type,
      onFocus: onInputFocus,
      onBlur: onInputBlur,
    };
    if (children) {
      // @ts-ignore
      return React.cloneElement(children, baseProps);
    }

    return <T ref={ref} {...baseProps} />;
  })();

  return (
    <div className={clsx(classes.container, containerClassName)}>
      {label && (
        <InputLabel name={rest.name} className={focused ? classes.focused : ""}>
          {label}
        </InputLabel>
      )}
      <div
        className={clsx(
          classes.inputContainer,
          {
            [classes.error!]: Boolean(errorText),
            [classes.textarea!]: Boolean(T === "textarea"),
          },
          className,
        )}
      >
        {left && <div className={classes.leftContainer}>{left}</div>}
        {inputElement}
        {right && <div className={classes.rightContainer}>{right}</div>}
        {variant === "password" && (
          <div className={classes.rightContainer}>
            <button
              type="button"
              className={classes.passwordButton}
              onClick={() => {
                type === "password" ? setType("text") : setType("password");
              }}
            >
              {type === "password" ? <FaEye size={18} /> : <FaEyeSlash size={18} />}
            </button>
          </div>
        )}
      </div>
      {errorText && <small className={classes.error}>{errorText}</small>}
    </div>
  );
});

export default InputField;
