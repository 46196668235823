/* eslint-disable no-useless-escape */
import type {
  APIResource,
  AdminLessonEntity,
  Course,
  LaravelValidationError,
  LessonEntity,
  Phrase,
  PhraseStringNotation,
  Product,
  RateableTestEntity,
} from "@rocketlanguages/types";
import { Children, isValidElement } from "react";
import { RateableTestIconColors, WritingSystemIds, WritingSystemLanguages } from "./constants";
import { prepareString } from "./stringUtils";

import type { AxiosError } from "axios";
import type { ColorProps } from "@rocketlanguages/ui/Button/Button";
import Courses from "../res/courses";
import type { ReactNode } from "react";
import { WritingSystemIds as WS } from "./constants";
import type { useSharedStore } from "../store";
import { memoize } from "./memoize";

const vercelStagingEnv = {
  MODE: "staging",
  API_URL: "https://staging-forge.rocketlanguages.com/api",
};

/**
 * Our process.env / vite env environment variables
 */
export const config = (baseKey: string, defaultValue: string) => {
  const prefixes = ["REACT_APP", "NEXT_PUBLIC"];

  // If we're on *.vercel.app, override default values
  if (
    typeof window !== "undefined" &&
    window.location &&
    window.location.href.includes(".vercel.app") &&
    baseKey in vercelStagingEnv
  ) {
    return vercelStagingEnv[baseKey as keyof typeof vercelStagingEnv];
  }

  for (const prefix of prefixes) {
    const key = `${prefix}_${baseKey}`;
    try {
      if (process.env[key]) {
        return process.env[key];
      }
    } catch (err) {
      //
    }
  }

  return defaultValue;
};

/**
 * Optimised implementation of Lodash's "omit" function.
 *
 * **Note**: Avoid using the import from Lodash as it will be removed in v5
 *
 * Removes keys from an object.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const omit = <T extends object, K extends keyof T>(obj: T, ...keys: K[]): Omit<T, K> => {
  return Object.keys(obj).reduce((newObject: any, key) => {
    // Account for numeric / string keys
    if (!keys.find((k) => k.toString() === key)) {
      newObject[key] = obj[key as K];
    }
    return newObject;
  }, {}) as Omit<T, K>;
};

export const capitalize = (str: string): string => str.charAt(0).toUpperCase() + str.substring(1);

export const sleep = (ms = 0): Promise<void> => new Promise((r) => setTimeout(r, ms));

export function resolveAsyncResource<T>(resource: APIResource<T> | undefined) {
  if (resource?.status === "loaded") {
    return resource.data;
  }
  return null;
}

/**
 * Gets the rating level [0-3] of a rateable test
 * @param {number} percentage Rating between 0-100
 */
export function getRatingLevel(percentage: number) {
  if (percentage >= 90) {
    return 3;
  }
  if (percentage >= 50) {
    return 2;
  }
  if (percentage > 0) {
    return 1;
  }
  return 0;
}

/**
 * Gets a red, grey, amber, green color based on the given percentage
 * @param {number} percentage between 0 - 100
 */
export function getRatingColor(percentage: number): string {
  const ratingLevel = getRatingLevel(percentage);
  return RateableTestIconColors[ratingLevel];
}

/**
 * Without extra reinforcement tests
 *
 * Used for tests involved in lessons without `is_extra` set to `1`
 */
export const withoutExtraTestsAndEnabled = memoize(<T extends RateableTestEntity>(rateableTests: T[]): T[] => {
  return rateableTests.filter((test) => !test.is_extra && test.status === 1);
});

/**
 * [Leaderboard position]
 * Takes in a number and returns the ordinal (i.e "1st, 2nd, 3rd, 4th")
 * @param {number} n
 * @return {string}
 */
export function getOrdinal(n: number): string {
  const s = ["th", "st", "nd", "rd"] as const;
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

/**
 * Takes in a number and returns a formatted string (i.e. "5 Days", "1 Day")
 * @param {number} streak - User streak in days
 */
export function getStreak(streak: number, dailyPoints: number): string {
  if (streak && streak > 0) {
    if (streak === 1) {
      return `${streak} Day`;
    }
    return `${streak} Days`;
  }
  if (dailyPoints > 0) {
    // Cache may not have the streak up to date
    return "1 Day";
  }

  return "-";
}

/** Checks if the course id is RTL */
export function isRTL(courseId: number) {
  return courseId === 14;
}

export const getCourseFromProductId = (productId: number) => {
  return Courses.find((c) => c.products.some((product) => product.id === productId));
};

export function isValidEmail(email: string) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

/** Custom writing system names for displaying title  */
const writeItWritingSystem: { [courseSlug: string]: number } = {
  chinese: WS.pinyin,
  hindi: WS.hindi,
  japanese: WS.hiragana_katakana,
  korean: WS.korean,
  russian: WS.russian,
};

const writeItNativeWritingSystem: { [courseSlug: string]: number } = {
  chinese: WS.hanzi,
  japanese: WS.kanji,
};

/**
 * Returns the writing system name
 *
 * e.g. "Spanish", "Cyrillic", "Devanagari"
 */
export function getWriteItWritingSystemName(options: { isNative: boolean; course: Course; onlyCustom?: boolean }) {
  const { isNative, course } = options;
  let ws: number | undefined;
  if (isNative) {
    ws = writeItNativeWritingSystem[course.slug];
  } else {
    ws = writeItWritingSystem[course.slug];
  }
  if (ws && WritingSystemLanguages[ws]) {
    return WritingSystemLanguages[ws];
  }
  if (options.onlyCustom) {
    return "";
  }
  return course.name;
}

// eslint-disable-next-line
export const noop = (_params?: any) => {};

export async function tryCatch<T>(promise: Promise<T | Error>): Promise<[null, T] | [Error]> {
  try {
    const data = await promise;
    if (data instanceof Error) {
      return [data];
    }
    return [null, data];
  } catch (err: any) {
    return [err];
  }
}

export function tryCatchFn<T>(fn: () => T): [null, T] | [Error, null] {
  try {
    const data = fn();
    if (data instanceof Error) {
      return [data, null];
    }
    return [null, data];
  } catch (err: any) {
    return [err, null];
  }
}

export function moduleTitleParser(moduleTitle: string) {
  const regex = /- Lessons/;
  return moduleTitle.replace(regex, "");
}

export const languageSplitCharacters = {
  [WritingSystemIds.english]: " ",
  [WritingSystemIds.hiragana_katakana]: "　",
  [WritingSystemIds.kanji]: "",
  [WritingSystemIds.hanzi]: "",
};

/**
 * Truncate a given string of text content at a specified character limit, to the nearest whole word
 * @param text text string to truncate
 * @param characters number of characters to allow before truncation
 */
export function truncateText(text: string, characters: number) {
  for (let i = characters; i < text.length; i++) {
    if (text[i] === " ") {
      return text.slice(0, i) + "...";
    }
  }
  return text;
}

/**
 * Takes a total amount of seconds as a number and converts to equivalent in minutes and seconds.
  Returns in MM:SS string format.
 * @param totalSeconds
 */
export function secondsToMinutesAndSeconds(totalSeconds: number | undefined) {
  if (!totalSeconds || Number.isNaN(Number(totalSeconds)) || totalSeconds < 1) {
    return "00:00";
  }
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = Math.floor(totalSeconds - minutes * 60);
  return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
}

// eslint-disable-next-line no-irregular-whitespace
export const allPunctuationRegexGlobal = new RegExp(/[»«…‘’"'“”.／～〜~¿،,！、!　。؟？，¡?*!]/g);

/**
 * Search an array of strings for a given search term
 * Returns true as soon as a match is found, or false if no match is found
 */
export function stringArrayContains(strArray: string[], searchQuery: string) {
  if (!searchQuery) {
    return false;
  }

  const formatStr = (str: string) => str.toLowerCase().replace(allPunctuationRegexGlobal, "");

  const formattedSearchQuery = formatStr(searchQuery);

  for (const str of strArray) {
    if (formatStr(str).includes(formattedSearchQuery)) {
      return true;
    }
  }

  return false;
}

/**
 * Takes an array and a start and end index, removes the item from the start index and inserts it at the end index.
 * @param array
 * @param firstIndex
 * @param lastIndex
 */
export function reorderArray<T>(array: T[], firstIndex: number, lastIndex: number): T[] {
  const result = Array.from(array);
  const [removed] = result.splice(firstIndex, 1);
  if (removed) {
    result.splice(lastIndex, 0, removed);
  }
  return result;
}

/**
 * Returns a grade string
 * @param value number between 0-100
 */
export function getGrade(value: number) {
  if (value === 0) return "-";
  if (value >= 98) return "A+";
  if (value >= 93) return "A";
  if (value >= 90) return "A-";
  if (value >= 88) return "B+";
  if (value >= 83) return "B";
  if (value >= 80) return "B-";
  if (value >= 78) return "C+";
  if (value >= 73) return "C";
  if (value >= 70) return "C-";
  if (value >= 60) return "D";
  if (value < 60) return "F";
  return "-";
}

/**
 * Takes any array and sorts it into alphabetic order by the values of the given key
 * @param array
 * @param key
 * @param reverse
 */
export function sortAlphabetically<T>(array: T[], key: keyof T, reverse?: boolean) {
  const sortedArray = array.sort((a, b) => {
    if (a[key] < b[key]) {
      if (reverse) {
        return 1;
      }
      return -1;
    } else if (a[key] > b[key]) {
      if (reverse) {
        return -1;
      }
      return 1;
    }
    return 0;
  });
  return sortedArray;
}

export function getApiResourceData<T>(resource: APIResource<T> | undefined) {
  return resource?.status === "loaded" ? resource.data : undefined;
}

/**
 * Scrolls the window to an offset without smooth scrolling
 */
export function scrollInstant(offsetY: number) {
  const html = document.getElementsByTagName("html")[0];
  if (!html) return;
  html.style.scrollBehavior = "auto";
  window.scrollTo({
    top: offsetY,
  });
  // Reverting inline style to empty
  html.style.scrollBehavior = "";
}

export function scrollIntoView(
  elementIdOrNode: string | HTMLElement,
  options?: { offset?: number; instant?: boolean; focus?: boolean },
) {
  const element = typeof elementIdOrNode === "string" ? document.getElementById(elementIdOrNode) : elementIdOrNode;
  if (element) {
    const elementPosition = element.offsetTop;
    const top = elementPosition - 60 - (options?.offset || 0);

    // Setting inline style of scroll-behavior to 'auto' temporarily
    if (options?.instant) {
      scrollInstant(top);
    } else {
      window.scrollTo({ top, behavior: "smooth" });
    }

    if (!options || typeof options.focus === "undefined" || options.focus) {
      element.focus({ preventScroll: true });
    }
  }
}

const congratulationsMessages = {
  default: ["Congratulations!", "Awesome!", "Great work!"],
  japanese: [
    "おめでとう！(Omedetō)",
    "すごい！(Sugoi!)",
    "よくできました！(Yoku dekimashita!)",
    "すばらしい！(Subarashī!)",
  ],
  chinese: [
    "恭喜恭喜！(Gōngxǐ gōngxǐ!)",
    "非常好！(Fēicháng hǎo!)",
    "干得漂亮！(Gàn de piàoliang!)",
    "不错！(Búcuò!)",
    "干得好！(Gàn de hǎo!)",
    "太棒了！(Tài bang le!)",
  ],
  french: ["Magnifique !", "Fantastique !", "Splendide !", "Superbe !", "Merveilleux !", "Super !"],
  german: ["Ausgezeichnet!", "Super!", "Sehr gut!", "Großartig!", "Gut gemacht!"],
  italian: ["Congratulazioni!", "Ottimo lavoro!", "Grande!", "Complimenti!", "Fantastico!", "Ben fatto!"],
  spanish: ["¡Maravilloso!", "¡Muy bien!", "¡Genial!", "¡Fantástico!", "¡Súper!", "¡Estupendo!"],
  arabic: ["!مَبْرُوك (Mabrōk!)", "!أَلْف مَبْرُوك (Alf Mabrōk!)", "!مُمْتَاز (Momtāz!)"],
  hindi: [
    "शाबाश! (Śābāś!)",
    "बहुत खूब! (Bahuta khūba!)",
    "बहुत अच्छा! (Bahuta acchā!)",
    "अद्भुत! (Adbhuta!)",
    "वाह! (Vāha!)",
  ],
  korean: ["축하합니다! (Chukhahamnida!)", "잘 했어요! (Jal haesseoyo!)", "대박! (Daebak!)"],
  portuguese: ["Parabéns!", "Muito bem!", "Ótimo!", "Ótimo trabalho!", "Maravilha!"],
  russian: [
    "Молодец! (maladets)",
    "Отлично! (atleechna)",
    "Очень хорошо! (ochin' harasho)",
    "Великолепно! (vilikalepna)",
    "Прекрасное начало! (prikrasnae nachala)",
    "Так держать! (tak dirzhat')",
    "Ты на верном пути! (ty na vernam putee)",
    "Здорово! (zdorava)",
  ],
} satisfies { [key: string]: string[] };

/**
 * Get a random congratulations message from the congratulations messages array for the given course slug
 */
export function getRandomCongratulationsMessage(courseSlug: string) {
  const messageArray =
    congratulationsMessages[courseSlug as keyof typeof congratulationsMessages] || congratulationsMessages.default;

  const randomIndex = Math.floor(Math.random() * Math.floor(messageArray.length));
  return messageArray[randomIndex] || "Congratulations!";
}

export const getWritingSystemIdFromCourse = (courseSlug: string, isNative: boolean) => {
  const nativeWritingSystems: Record<string, [number, number] | undefined> = {
    chinese: [WS.pinyin, WS.hanzi],
    japanese: [WS.hiragana_katakana, WS.kanji],
  };
  return nativeWritingSystems[courseSlug]?.[isNative ? 1 : 0];
};

export const getWritingSystemIndex = memoize(
  (courseSlug: string, phrase: Phrase | undefined, isNative: boolean) => {
    const nativeWritingSystemId = getWritingSystemIdFromCourse(courseSlug, isNative);
    if (phrase && nativeWritingSystemId) {
      const index = phrase.strings?.findIndex((str) => str.writing_system_id === nativeWritingSystemId);
      return index ?? 0;
    }
    return isNative ? 1 : 0;
  },
  { maxSize: 1 },
);

/** (Vocab) Remove all reserved regex characters */
export const reservedRegexSyntax = /([/()?[\]*\\{}^$.-])/g;

export class UniqueSet<T> {
  _iterable = [] as T[];
  _set = new Set<string>();

  add(key: string, value: T) {
    if (!this._set.has(key)) {
      this._set.add(key);
      this._iterable.push(value);
    }
  }

  toArray() {
    return this._iterable;
  }
}

export function getRatingPercentageDisplay(rawPercentage: number) {
  return Math.min(Math.ceil(rawPercentage / 5) * 5, 100);
}

export function convertMiliseconds(milliseconds: number): { d: string; h: string; m: string; s: string } {
  const total_seconds = Math.floor(milliseconds / 1000);
  const total_minutes = Math.floor(total_seconds / 60);
  const total_hours = Math.floor(total_minutes / 60);
  const total_days = Math.floor(total_hours / 24);

  const seconds = total_seconds % 60;
  const minutes = total_minutes % 60;
  const hours = total_hours % 24;

  const secondsString = `${seconds}`.length <= 1 ? `0${seconds}` : `${seconds}`;
  const minutesString = `${minutes}`.length <= 1 ? `0${minutes}` : `${minutes}`;
  const hoursString = `${hours}`.length <= 1 ? `0${hours}` : `${hours}`;

  return { d: `${total_days}`, h: hoursString, m: minutesString, s: secondsString };
}

/** Expects a module title in 'Module X - Lessons format */
export function getModuleNumberFromTitle(title: string) {
  const titleArray = title.split(" ");
  const titleNumber = titleArray[1];
  return Number(titleNumber);
}

export const getModuleLabel = (options: { levelId: number; title: string }) => {
  const { levelId, title } = options;
  // eBook
  if (levelId === 9) {
    return "Chapters";
  }
  const titleNumber = getModuleNumberFromTitle(title);
  if (levelId === 4) {
    return `Chapter ${titleNumber || ""}`;
  }
  if (title) {
    return title;
  }
  if (isNaN(titleNumber)) {
    return "Survival Kit";
  }
  return `Module ${titleNumber}`;
};

/** Return a shuffled copy of an array */
export function shuffleArray<T>(array: T[]): T[] {
  const arrayCopy = [...array];
  for (let i = arrayCopy.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    // @ts-ignore
    [arrayCopy[i], arrayCopy[j]] = [arrayCopy[j], arrayCopy[i]];
  }
  return arrayCopy;
}

export const isTableRegex = new RegExp(/[*+A-Za-z**+]\s?\|\s?[*+A-Za-z*+]/, "gi");

export function getLaravelValidationError(error: AxiosError<LaravelValidationError>): string | undefined {
  if (typeof error?.response?.data?.errors === "object") {
    // { identity_number: ["Your Library Card number is required"] }
    const firstError = Object.values(error.response.data.errors)[0];
    if (typeof firstError === "string") {
      return firstError;
    }
    // Otherwise return first error
    if (Array.isArray(firstError)) {
      return firstError[0];
    }
  }
  return undefined;
}

export function getErrorMessage(err: any, fallback = "An error occurred") {
  return getLaravelValidationError(err) || err.message || fallback;
}

export function generateCacheKeyFromPreferences(
  prefix: string,
  preferences: Record<string, string | number | Record<string, any> | undefined>,
  id?: number | string,
) {
  let key = id ? `${prefix}-${id}` : prefix;
  [...Object.values(preferences)].forEach((val) => {
    if (typeof val === "string" || typeof val === "number") {
      key = key.concat("-", `${val}`);
    } else if (val) {
      key = generateCacheKeyFromPreferences(key, val);
    }
  });
  return key;
}

export function sortNotations(notations: PhraseStringNotation[]) {
  return [...notations].sort((a, b) => {
    const aNumber = Number(a.mode_value.split("-")[0]);
    const bNumber = Number(b.mode_value.split("-")[0]);
    return aNumber - bNumber;
  });
}

export function getOrderedModulesFromStore(modulesList: {
  [key: number]: {
    display_title: string;
    id: number;
    number: string;
    product_level_id: number;
    proficiency_level_id: number | null;
    title: string | null;
    course_id: number;
  };
}) {
  return [...Object.keys(modulesList)]
    .sort((a, b) => {
      const numberOfA = Number(modulesList[Number(a)]?.number);
      const numberOfB = Number(modulesList[Number(b)]?.number);
      if (numberOfA < numberOfB) return -1;
      if (numberOfA > numberOfB) return 1;
      return 0;
    })
    .map((key) => modulesList[Number(key)]);
}

export function getProductsFromCourse(courseId: number) {
  const course = Courses.find((_course) => _course.id === courseId);
  return course ? course.products : [];
}

// return only first appearance of products with shared level ids, eg: Rocket Spanish Level 1 vs Rocket Spanish Level 1 (Trial)
export function removeDuplicateProductLevels(products: Product[]) {
  const productsByLevelId: Map<number, Product> = new Map();
  products.forEach((_product) => {
    if (!productsByLevelId.has(_product.level_id)) {
      productsByLevelId.set(_product.level_id, _product);
    }
  });
  return [...productsByLevelId.values()];
}

export function getHoursFromSeconds(ss: number) {
  const hours = ss / 3600;
  const minutes = (ss - Math.floor(hours) * 3600) / 60;
  const flooredHours = Math.floor(hours);
  const formattedHours = flooredHours < 10 ? `0${flooredHours}` : flooredHours;
  const flooredMinutes = Math.floor(minutes);
  const formattedMinutes = flooredMinutes < 10 ? `0${flooredMinutes}` : flooredMinutes;
  return `${formattedHours}:${formattedMinutes}`;
}

export function getProductFromId(productId: number): Product | null {
  for (let i = 0; i < Courses.length; i++) {
    const product = Courses[i]?.products?.find((_product) => _product.id === productId);
    if (product) {
      return product;
    }
  }
  return null;
}

export function isFreeResource(lesson: LessonEntity | AdminLessonEntity) {
  return lesson.lesson_type_id === 5;
}

/** Capital case every word */
export function capitalCase(str: string) {
  return str
    .replace(/_/g, " ")
    .replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase());
}

/** Value to determine where this library is being used in. Useful for conditionally loading */
export const buildContext =
  typeof process !== "undefined" && typeof process.env.NEXT_PUBLIC_API_URL === "string" ? "nextjs" : "spa";

export function RequireLib<T>(props: { nextjs: () => T; spa: () => T }) {
  switch (buildContext) {
    case "nextjs":
      return props.nextjs();
    case "spa":
      return props.spa();
    default:
      throw new Error("Unknown build context");
  }
}

export function debounce<F extends (...args: any[]) => void>(func: F, wait?: number) {
  wait = wait || 100;
  let timeoutID: any = null;
  const fn = function (this: any, ...args: Parameters<F>) {
    clearTimeout(timeoutID);
    timeoutID = setTimeout(() => func.apply(this, args), wait);
  } as F & { cancel: () => void };

  // add cancel method to the debounced function
  fn.cancel = function () {
    clearTimeout(timeoutID);
  };

  return fn;
}

export function asyncDebounce<F extends (...args: any[]) => Promise<any>>(func: F, wait?: number) {
  const debounced = debounce((resolve: any, reject: any, args: Parameters<F>) => {
    func(...args)
      .then(resolve)
      .catch(reject);
  }, wait);

  return (...args: Parameters<F>): ReturnType<F> =>
    new Promise((resolve, reject) => {
      debounced(resolve, reject, args);
    }) as ReturnType<F>;
}

export function toRadians(degrees: number) {
  return degrees * (Math.PI / 180);
}

/**
 * Creates an array of elements split into two groups, the first of which
 * contains elements `predicate` returns truthy for, the second of which
 * contains elements `predicate` returns falsey for. The predicate is
 * invoked with one argument: (value).
 *
 * @since 3.0.0
 * @category Collection
 * @param {Array|Object} collection The collection to iterate over.
 * @param {Function} predicate The function invoked per iteration.
 * @returns {Array} Returns the array of grouped elements.
 * @see groupBy, keyBy
 * @example
 *
 * const users = [
 *   { 'user': 'barney',  'age': 36, 'active': false },
 *   { 'user': 'fred',    'age': 40, 'active': true },
 *   { 'user': 'pebbles', 'age': 1,  'active': false }
 * ]
 *
 * partition(users, ({ active }) => active)
 * // => objects for [['fred'], ['barney', 'pebbles']]
 */
export function partition<T>(collection: Array<T>, predicate: (item: T) => boolean): [T[], T[]] {
  return collection.reduce((result, value) => (result[predicate(value) ? 0 : 1].push(value), result), [[], []] as [
    T[],
    T[],
  ]);
}

export default partition;
// search for a number between an array of ranges
export function binarySearchRange(
  needle: number,
  haystack: Array<{
    start: number;
    end: number;
  }>,
) {
  function doSearch(startIndex: number, endIndex: number): number {
    // boundary check
    if (startIndex > endIndex) {
      return -1;
    }
    // get pivot
    const pivot = Math.floor((startIndex + endIndex) / 2);

    const el = haystack[pivot];
    if (!el) {
      return -1;
    }
    // Element start time & end time is between given timestamp
    if (needle >= el.start && needle <= el.end) {
      return pivot;
    }

    // Search left side of array
    if (needle < el.start) {
      return doSearch(startIndex, pivot - 1);
    }

    // Search right side of arr
    if (needle > el.end) {
      return doSearch(pivot + 1, endIndex);
    }

    // not found
    return -1;
  }

  return doSearch(0, haystack.length - 1);
}

export function getWritingSystemIndexForTranscriptLines(courseSlug: string, phrase: Phrase, isNative: boolean) {
  if (courseSlug === "japanese") {
    return phrase.strings.findIndex((_phraseString) => _phraseString.writing_system_id === WritingSystemIds["romaji"]);
  }
  return getWritingSystemIndex(courseSlug, phrase, isNative);
}

export function getRatingButtonColorProp(ratingLevel: number): ColorProps {
  switch (ratingLevel) {
    case 1:
      return "error";
    case 2:
      return "warning";
    case 3:
      return "success";
    case 0:
      return "grey";
    default:
      return "grey";
  }
}

/**
 * Splits rateable tests in to two groups: tests & extra tests
 *
 * Extra tests are any individual rateable tests that have `is_extra` set
 */
export const getTestGroups = memoize(
  (rateableTests: RateableTestEntity[]) => {
    const tests: RateableTestEntity[] = [];
    const extraTests: RateableTestEntity[] = [];

    for (const test of rateableTests) {
      if (test.is_extra) {
        extraTests.push(test);
      } else {
        tests.push(test);
      }
    }

    return {
      tests,
      extraTests,
    };
  },
  { maxSize: 1 },
);

// eslint-disable-next-line no-irregular-whitespace
const slugifyTrimPunctuationRegex = /([‘’\"'“”`<>|.~¿,！、　。？，¡?!():*_])/g;

/** Utilities to get text from ReactNode */
const hasChildren = (element: ReactNode) => isValidElement(element) && Boolean(element.props.children);
const childToString = (child?: ReactNode): string => {
  if (typeof child === "undefined" || child === null || typeof child === "boolean") {
    return "";
  }

  if (JSON.stringify(child) === "{}") {
    return "";
  }

  return (child as number | string).toString();
};

export const onlyText = (children: ReactNode | ReactNode[]): string => {
  if (!(children instanceof Array) && !isValidElement(children)) {
    return childToString(children);
  }

  return Children.toArray(children).reduce((text: string, child: ReactNode): string => {
    let newText = "";

    if (isValidElement(child) && hasChildren(child)) {
      newText = onlyText(child.props.children);
    } else if (isValidElement(child) && !hasChildren(child)) {
      newText = "";
    } else {
      newText = childToString(child);
    }

    return text.concat(newText);
  }, "");
};

export const slugify = (text: string) => {
  return (
    text
      // Remove punctuation
      .replace(slugifyTrimPunctuationRegex, "")
      .trim()
      // Replace all separator characters and whitespace by a single separator
      .replace(/[\s_-]/g, "-")
      .toLowerCase()
  );
};

export const phraseAnswerToArray = (answer?: string | null): string[] => {
  return answer ? JSON.parse(answer) : [];
};

// Filter answers by answers that wouldn't match a phrase string
export const validatePhraseAnswers = (answers: string[], phrase: Phrase) => {
  return answers.filter((answer) => {
    const preparedAnswer = prepareString(answer);
    // Make sure that it can't be matched by the RR algo
    const matchesPhrase = phrase.strings.some((ps) => prepareString(ps.text) === preparedAnswer);
    return !matchesPhrase;
  });
};

export function compareValues(a: number, b: number, marginOfError = 0) {
  if (a === b) return 0;
  if (a > b) {
    if (a - b <= marginOfError) return 0;
    return 1;
  }
  if (b - a <= marginOfError) return 0;
  return -1;
}

/** Gets the next rateable test for the current lesson */
export const getNextRateableTest = (params: {
  rateableTestId: number;
  lessonId: number;
  store: ReturnType<typeof useSharedStore>;
}) => {
  const { rateableTestId, lessonId, store } = params;
  const rateableTestIds = store.getState().lesson.entities.lesson_rateable_test_ids[lessonId];

  if (!rateableTestIds) {
    return null;
  }

  const currentRateableTestIndex = rateableTestIds?.findIndex((id) => id === rateableTestId);

  if (currentRateableTestIndex === -1) {
    return null;
  }

  const nextRateableTestId = rateableTestIds?.[currentRateableTestIndex + 1];

  if (!nextRateableTestId) {
    return null;
  }

  return store.getState().lesson.entities.rateable_tests[nextRateableTestId];
};
