import { forwardRef } from "react";

import { type LinkButtonProps } from "./LinkButton";
import SquareButton from "./SquareButton";

const defaultProps: LinkButtonProps = {
  color: "primary",
  size: "medium",
};

const SquareLinkButton = forwardRef<HTMLButtonElement, LinkButtonProps>((props, ref) => {
  return (
    <SquareButton use="a" ref={ref} {...props}>
      {props.children}
    </SquareButton>
  );
});

SquareLinkButton.defaultProps = defaultProps;

export default SquareLinkButton;
