import type { Quiz, QuizQuestion } from "@rocketlanguages/types";
import { useMemo, useEffect, useReducer } from "react";
import { requestRateTest, requestAddPoints, asyncResetTest } from "../store/lesson/actions";
import { action, type ActionType } from "typesafe-actions";
import { useRateableTestRatingLevel } from "./useLessonRateableTests";
import { useSharedSelector, useSharedDispatch } from "../store";
import useGetter from "./useGetter";
import { memoize } from "../utils/memoize";

export interface QuizState {
  index: number;
  selected: number[];
  answered: Array<{ answerId: number; isCorrect: boolean }>;
  isComplete: boolean;
  numQuestions: number;
}

/** Initialize quiz state with num questions */
const getInitialState = memoize(
  (ratingLevel: number, numQuestions: number): QuizState => {
    return {
      answered: [],
      selected: [],
      index: 0,
      isComplete: ratingLevel > 0,
      numQuestions,
    };
  },
  { maxSize: 1 },
);

type useQuizTestProps = { lessonId: number; rateableTestId: number; onComplete?: (ratingValue: number) => void };

export default function useQuizTest(props: useQuizTestProps) {
  const quiz = useSharedSelector((state) => state.lesson.entities.lesson_quiz[props.lessonId]);
  const ratingLevel = useRateableTestRatingLevel(props.rateableTestId);
  return useQuizTestWithRatingLevel(quiz!, props, { ratingLevel, testMode: "default" });
}

export function useCustomQuizTest(quiz: Quiz, props: useQuizTestProps) {
  return useQuizTestWithRatingLevel(quiz, props, { ratingLevel: 0, testMode: "moduletest" });
}

function useQuizTestWithRatingLevel(
  quiz: Quiz,
  props: useQuizTestProps,
  options: { ratingLevel: number; testMode: "moduletest" | "default" },
) {
  const { ratingLevel, testMode } = options;
  const activeProductId = useSharedSelector((state) => state.preferences.activeProduct?.id);
  const dispatch = useSharedDispatch();
  const [quizQuestions, sectionOffsets] = useMemo(() => getQuestionsAndOffsets(quiz), [quiz]);

  const [state, dispatchLocal] = useReducer(reducer, getInitialState(ratingLevel, quizQuestions.length));

  useEffect(() => {
    dispatchLocal(actions.updateNumQuestions(quizQuestions.length));
  }, [quizQuestions.length]);

  const currentSection = useMemo(
    () => getCurrentSection(quiz, sectionOffsets, state.index),
    [quiz, sectionOffsets, state.index],
  );

  const percentComplete = ((state.index + 1) / quizQuestions.length) * 100;

  /**
   * Calculates the rating and updates the rating on the server & client
   */
  function completeQuiz() {
    if (!quiz || !activeProductId) {
      return;
    }
    // Calculate number of questions correct
    const numCorrect = state.answered.reduce((prev, curr) => prev + Number(curr.isCorrect), 0);

    const rating = (numCorrect / quizQuestions.length) * 100;

    if (props.onComplete) {
      props.onComplete(rating);
    }

    if (testMode !== "moduletest") {
      // Dispatch action to rate quiz on the server and client
      dispatch(
        requestRateTest({
          productId: activeProductId,
          // lessonId: props.lessonId,
          rateableTestId: props.rateableTestId,
          rating,
        }),
      );
      // Dispatch action to add points
      dispatch(
        requestAddPoints({
          rewardType: "completeQuiz",
          data: { lesson: props.lessonId },
        }),
      );
    }
  }

  const getCompleteQuizFn = useGetter(completeQuiz);

  useEffect(() => {
    if (state.index >= quizQuestions.length && quizQuestions.length > 0) {
      const complete = getCompleteQuizFn();
      complete();
    }
  }, [state.index, quizQuestions.length, getCompleteQuizFn]);

  /**
   * User presses on the 'CHECK' button
   */
  function confirmAnswer() {
    // No questions loaded, or array out of bounds
    if (!Array.isArray(quizQuestions) || quizQuestions.length < state.index || state.index < 0) {
      return;
    }

    const selectedAnswerId = state.selected[state.index];
    const question = quizQuestions[state.index];
    const answer = question?.answers.find((a) => a.id === selectedAnswerId);

    // No answer selected
    if (!state.selected || !answer) {
      return;
    }

    dispatchLocal(
      actions.confirmAnswer({
        answerId: answer.id,
        isCorrect: Boolean(answer.is_correct),
      }),
    );
  }

  function reset() {
    if (quiz) {
      dispatch(
        asyncResetTest({
          rateableTestId: props.rateableTestId,
          lessonId: props.lessonId,
        }),
      );
      dispatchLocal(actions.reset());
    }
  }

  return {
    state,
    dispatch: dispatchLocal,
    status: quiz ? "loaded" : "loading",
    methods: {
      reset,
      confirmAnswer,
    },
    computed: {
      quizQuestions,
      percentComplete,
      currentSection,
      ratingLevel,
    },
  };
}

/**
 * Flattens all quiz sections to an array of questions and gets the offsets of each section
 */
const getQuestionsAndOffsets = (quiz: Quiz | undefined): [QuizQuestion[], number[]] => {
  if (!quiz) {
    return [[], []];
  }
  let quizQuestions: QuizQuestion[] = [];
  const sectionOffsets = [0];
  const { sections } = quiz;
  for (const section of sections) {
    const offset = sectionOffsets[sectionOffsets.length - 1];
    if (typeof offset === "number") {
      sectionOffsets.push(offset + section.questions.length);
      quizQuestions = [...quizQuestions, ...section.questions];
    }
  }

  return [quizQuestions, sectionOffsets];
};

/**
 * Works out what section of the quiz the person is on
 */
function getCurrentSection(quiz: Quiz | undefined, sectionOffsets: number[], index: number) {
  // Make sure there's a current quiz
  if (!quiz?.sections) {
    return null;
  }

  let offset = 0;
  if (sectionOffsets) {
    for (let i = sectionOffsets.length; i > 0; i -= 1) {
      const sectionOffset = sectionOffsets[i];
      if (typeof sectionOffset === "number" && sectionOffset <= index) {
        offset = i;
        break;
      }
    }
  }

  return quiz.sections[offset];
}

export const actions = {
  updateNumQuestions(numQuestions: number) {
    return action("UPDATE_NUM_QUESTIONS", numQuestions);
  },
  next() {
    return action("NEXT");
  },
  /**
   * When a user selects a potential option
   */
  selectAnswer(answerId: number) {
    return action("SELECT_ANSWER", {
      answerId,
    });
  },
  reset() {
    return action("RESET");
  },
  /**
   * User presses on the 'CHECK' button
   */
  confirmAnswer(payload: { answerId: number; isCorrect: boolean }) {
    return action("CONFIRM_ANSWER", payload);
  },
};

type QuizActions = ActionType<typeof actions>;

function reducer(state: QuizState, action: QuizActions): QuizState {
  switch (action.type) {
    case "UPDATE_NUM_QUESTIONS": {
      return {
        ...state,
        numQuestions: action.payload,
      };
    }
    case "NEXT": {
      const nextIndex = state.index + 1;
      return {
        ...state,
        index: nextIndex,
        isComplete: Boolean(nextIndex >= state.numQuestions),
      };
    }
    // User selects a quiz item
    case "SELECT_ANSWER":
      return {
        ...state,
        selected: Object.assign([...state.selected], {
          [state.index]: action.payload.answerId,
        }),
      };
    // User taps "confirm"
    case "CONFIRM_ANSWER":
      return {
        ...state,
        answered: [...state.answered, action.payload],
      };
    case "RESET":
      return getInitialState(0, state.numQuestions);
    default:
      return state;
  }
}
