import { type ReactNode, useCallback, useContext, useEffect, useRef } from "react";
import usePhraseTest, { actions as phraseTestActions } from "../../../hooks/usePhraseTest/usePhraseTest";
import Instructions from "./includes/Instructions";
import LessonContext from "../../../context/LessonContext";
import { Phrasebox, useWebRocketRecord } from "../../PhraseboxFacelift/PhraseboxFacelift";
import RateableTest from "../RateableTest";
import RateableTestContext from "../includes/context";
import { RateableTestTypeIds } from "../../../utils/constants";
import type { RatedPhrase } from "../../../hooks/useRocketRecord/types";
import useIsFocusedWithin from "../../../hooks/useIsFocusedWithin";
import useTranslation from "../../../hooks/useTranslation";
import { RoundedButton } from "@rocketlanguages/ui";
import type { Phrase } from "@rocketlanguages/types";
import { clsx } from "clsx";
import AudioRecorder from "../../Phrasebox/includes/RocketRecord/AudioRecorder";
import Voice from "../../Phrasebox/includes/RocketRecord/Voice";
import { MissileRatingButtons } from "../RateableTestUI/buttons/MissileRatingButtons";
import { getRecommendedRatingButton } from "../RateableTestUI/buttons/getRecommendedRatingButton";
import type { RatingGroups } from "../../../hooks/usePhraseTest/useRatingGroups";
import usePhraseHotkeys from "../../../hooks/usePhraseHotkeys";

export function KnowItFacelift({ rateableTestId }: { rateableTestId: number }) {
  const t = useTranslation();
  const lesson = useContext(LessonContext);

  const phraseTest = usePhraseTest({
    testTypeId: RateableTestTypeIds.KNOW_IT,
    lessonId: lesson.id,
    rateableTestId,
    mode: "unrated_components",
  });

  return (
    <RateableTest
      phraseTest={phraseTest}
      testTypeId={RateableTestTypeIds.KNOW_IT}
      testName={t("know-it")}
      testSubheading={t("know-it-subheading")}
      instructions={<Instructions />}
    >
      <KnowItPhraseTest />
    </RateableTest>
  );
}

export const KnowItPhraseTest = () => {
  const { phraseTest, testContainerRef } = useContext(RateableTestContext);
  const currentPhrase = phraseTest.components.testPhrases[phraseTest.state.index];
  const currentTestIsFocused = useIsFocusedWithin(testContainerRef);
  const isRevealed = phraseTest.state.revealed.has(phraseTest.state.index);
  const t = useTranslation();

  const focusTestContainer = () => {
    testContainerRef.current?.focus({ preventScroll: true });
  };

  const onRate = (ratingLabel: keyof RatingGroups) => {
    phraseTest.methods.dispatch(phraseTestActions.setSuggestedRatingLevel(0));
    phraseTest.methods.rate(ratingLabel);
    focusTestContainer();
  };

  const onReveal = () => {
    phraseTest.methods.dispatch(phraseTestActions.reveal(0));
    focusTestContainer();
  };

  usePhraseHotkeys({ phraseTestRef: testContainerRef, revealed: isRevealed, onReveal, onRate });

  if (!currentPhrase) {
    return null;
  }

  return (
    <div className="flex flex-1 flex-col gap-4 pt-6">
      <KnowItPhrasebox
        index={phraseTest.state.index}
        revealed={phraseTest.state.revealed.has(phraseTest.state.index)}
        // Addings a key solves an issue where the playback state gets stuck
        // in the "playing" state when going to the next phrase
        key={currentPhrase?.id}
        phrase={currentPhrase}
        onReveal={(ratingLevel) => phraseTest.methods.dispatch(phraseTestActions.reveal(ratingLevel))}
        onSetSuggestedRatingLevel={(ratingLevel) =>
          phraseTest.methods.dispatch(phraseTestActions.setSuggestedRatingLevel(ratingLevel))
        }
        handleKeyEvents={currentTestIsFocused}
      />
      <div className="flex h-20 flex-col items-center justify-end">
        {isRevealed ? (
          <MissileRatingButtons
            text="How hard did you find this phrase?"
            recommended={getRecommendedRatingButton(phraseTest.state.revealed.get(phraseTest.state.index))}
            onRate={onRate}
            numEasy={phraseTest.computed.ratings.easy.size}
            numGood={phraseTest.computed.ratings.good.size}
            numHard={phraseTest.computed.ratings.hard.size}
          />
        ) : (
          <RoundedButton
            className="w-full max-w-60 border border-missilebrand font-semibold text-missilebrand hover:bg-missilebrand hover:text-white dark:bg-missilebrand dark:text-white"
            onClick={onReveal}
          >
            {t("reveal")}
          </RoundedButton>
        )}
      </div>
    </div>
  );
};

interface KnowItPhraseboxProps {
  index: number;
  phrase: Phrase;
  revealed: boolean;
  onReveal: (ratingLevel: number) => void;
  onSetSuggestedRatingLevel: (ratingLevel: number) => void;
  children?: ReactNode;
  handleKeyEvents: boolean;
}

export function KnowItPhrasebox({
  index,
  phrase,
  children,
  revealed: isRevealed,
  onReveal,
  onSetSuggestedRatingLevel,
  handleKeyEvents,
}: KnowItPhraseboxProps) {
  const onRevealRef = useRef(onReveal);
  const onSetSuggestedRatingLevelRef = useRef(onSetSuggestedRatingLevel);
  onRevealRef.current = onReveal;
  onSetSuggestedRatingLevelRef.current = onSetSuggestedRatingLevel;

  const onRecordFinish = useCallback((params: { phraseId: number; result?: RatedPhrase; blobUrl?: string }) => {
    const { result } = params;
    if (!result) {
      return;
    }
    if (result.ratingLevel === 3) {
      onRevealRef.current(result.ratingLevel);
    } else if (result.percentage > 10) {
      onSetSuggestedRatingLevelRef.current(result.ratingLevel);
    }
  }, []);

  const audioPlayerRef = useRef<HTMLAudioElement | null>(null);

  const rocketRecord = useWebRocketRecord({
    phrase,
    onRecordFinish,
  });

  useEffect(() => {
    if (isRevealed) {
      audioPlayerRef.current?.play();
    }
  }, [isRevealed]);

  useEffect(() => {
    return () => {
      const { actions, flag } = rocketRecord.useRocketRecordState.getState();
      if (["ACTIVE", "STARTING", "FINISHING"].includes(flag.status)) {
        // Clear speech timeouts
        actions.clearTimeouts();
        AudioRecorder?.finishRecording(false);
        Voice.stop();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  const hasMoreThanOneWritingSystem = phrase.strings.length > 2;
  const hasNotations = !!phrase.literal_string || phrase.strings.some((s) => s.notations.length > 0);
  return (
    <Phrasebox rocketRecord={rocketRecord}>
      {children}
      {handleKeyEvents && <Phrasebox.Hotkeys playDisabled={!isRevealed} />}

      <div className="relative">
        <div
          className={clsx(
            "rounded-2xl border-2 border-missilestroke p-4 dark:bg-missilesurfacelight",
            hasNotations && "rounded-tr-none",
          )}
        >
          <Phrasebox.Notations />
          <Phrasebox.RowLayout>
            <Phrasebox.PlayButton
              audioRef={audioPlayerRef}
              disabled={!isRevealed}
              className={clsx(
                !isRevealed ? "opacity-50" : "hover:bg-gray-300",
                "bg-missilesurfacedark text-missilebrand dark:text-white hover:dark:bg-gray-600",
              )}
              useDarkWaveform
            />
            <Phrasebox.StringsContainer>
              <Phrasebox.Strings>
                {(phraseString, index, items) => {
                  const isLast = index === items.length - 1;
                  const shouldShowBottomBorder = hasMoreThanOneWritingSystem && !isLast;
                  const shouldHide = !isRevealed && index !== items.length - 1;

                  return (
                    <div
                      className={clsx(
                        shouldShowBottomBorder ? "mb-2 w-full border-b border-b-missilestroke pb-2" : undefined,
                        shouldHide && "select-none",
                      )}
                    >
                      <Phrasebox.String {...phraseString} text={shouldHide ? "..." : phraseString.text} />
                    </div>
                  );
                }}
              </Phrasebox.Strings>
            </Phrasebox.StringsContainer>
          </Phrasebox.RowLayout>
          <Phrasebox.RowLayout className="h-6 items-center">
            <Phrasebox.RocketRecordRatingButton />
            <div className="mb-2 flex-1 border-b border-b-missilestroke pb-2" />
          </Phrasebox.RowLayout>
          <Phrasebox.RowLayout className="items-center pt-2">
            <Phrasebox.RecordButton className="hover:bg-missileaccent/90 bg-missileaccent text-white" />

            <Phrasebox.SpeechLayout className="flex-1">
              <Phrasebox.Speech />
              <Phrasebox.ErrorText />
              <Phrasebox.DebugPanel />
            </Phrasebox.SpeechLayout>

            <Phrasebox.RecordPlaybackButton
              className="bg-missilesurfacedark text-missilebrand hover:bg-gray-300 dark:text-white hover:dark:bg-gray-600"
              useDarkWaveform
            />
          </Phrasebox.RowLayout>
        </div>
      </div>
    </Phrasebox>
  );
}
