import { forwardRef } from "react";

import { clsx } from "clsx";
import IconButton, { type IconButtonProps, type Labelled } from "./IconButton";

type IconOutlineButtonProps = Labelled &
  Omit<IconButtonProps, "color"> & {
    color?: IconButtonProps["color"] | "white";
  };

const IconOutlineButton = forwardRef<HTMLButtonElement, IconOutlineButtonProps>((props, ref) => {
  const { color = "primary", size: propsSize = "medium", className, ...rest } = props;

  const colors = (() => {
    switch (color) {
      case "primary":
        return "text-brand border-2 border-brand hover:bg-brand";
      case "secondary":
        return "text-rocketblue border-2 border-rocketblue hover:bg-rocketblue";
      case "success":
        return "text-rocketgreen border-2 border-rocketgreen hover:bg-rocketgreen";
      case "warning":
        return "text-rocketorange border-2 border-rocketorange hover:bg-rocketorange";
      case "error":
        return "text-rocketred border-2 border-rocketred hover:bg-rocketred";
      case "white":
        return "text-white border-2 border-white bg-transparent hover:border-white/[.75] hover:text-white/[.75] hover:bg-transparent active:bg-transparent";
      default:
        return "text-brand border-2 border-brand hover:bg-brand";
    }
  })();

  return (
    <IconButton
      ref={ref}
      {...rest}
      size={propsSize}
      className={clsx(colors, "bg-transparent hover:text-white", className)}
    >
      {props.children}
    </IconButton>
  );
});

export default IconOutlineButton;
