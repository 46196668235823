import type { AudioComponent } from "@rocketlanguages/types";
import { createContext, type ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { noop } from "../../utils";
import { getSubtitleUrlFromTranscript } from "../../utils/web";
import { requestAddPoints, saveAudioProgress, updateEntity } from "../../store/lesson/actions";

import API from "../../res/Api";
import { omit } from "../../utils";
import useInterval from "../../hooks/useInterval";
import useMedia from "../../hooks/useMedia";
import { useSharedDispatch, useSharedStore } from "../../store";

type ComponentInstance = {
  component: AudioComponent;
  title: string;
  lessonId: number;
  lessonTitle: string;
};

type Players = { [componentId: number]: ReturnType<typeof useMedia> };

interface AudioContextState {
  activeComponentId: number;
  activeTrack: ComponentInstance | null;
  activePlayer: ReturnType<typeof useMedia> | null;
  registerComponent(params: ComponentInstance): void;
  unregisterComponent(componentId: number): void;
  play(componentId: number): void;
  pause(componentId: number): void;
  stop(componentId: number): void;
  players: Players;
  activeCue: { [componentId: number]: string };
  productId: number;
  setProductId(arg: number): void;
}

const AudioContext = createContext<AudioContextState>({
  activeCue: {},
  activeComponentId: -1,
  activeTrack: null,
  activePlayer: null,
  registerComponent: noop,
  unregisterComponent: noop,
  play: noop,
  pause: noop,
  stop: noop,
  players: {},
  productId: 0,
  setProductId: noop,
});

interface AudioWithUseMediaProps {
  onUpdate(componentId: number, player: ReturnType<typeof useMedia>): void;
  // onCueChange(componentId: number, subtitle: string): void;
  component: AudioComponent;
  lessonId: number;
}

const AudioWithUseMedia = (props: AudioWithUseMediaProps) => {
  const { component, lessonId } = props;
  const dispatch = useSharedDispatch();
  const mediaRef = useRef<HTMLAudioElement>(null);
  const trackRef = useRef<HTMLTrackElement>(null);
  const params = useMemo(() => ({ mediaRef, component, lessonId, trackRef }), [component, lessonId]);
  const player = useMedia(params);
  const store = useSharedStore();

  useEffect(() => {
    props.onUpdate(props.component.id, player);
  }, [player, props, props.component.id]);

  useInterval(
    () => {
      // Unauthenticated users come from our website
      if (store.getState().auth.status === "loggedin") {
        const media = player.ref.current || { currentTime: 0, duration: 0 };
        const percent = Math.floor((media.currentTime / (media.duration || 0)) * 100);
        API.post(["v2/audio-component/{audioComponent}/sync-time", { audioComponent: props.component.id }], {
          seconds: media.currentTime || 0,
          percent,
        });

        // Update lesson progress
        dispatch(updateEntity({ user_lesson_progress: { [lessonId]: { progress: percent } } }));
      }
      dispatch(
        saveAudioProgress({
          audioComponentId: props.component.id,
          seekTimeSeconds: player.ref.current?.currentTime || 0,
        }),
      );
    },
    player.isPlaying ? 10000 : null,
  );

  usePointsRewarderInterval(player.isPlaying, lessonId);

  return useMemo(
    () => (
      <audio ref={mediaRef} preload="metadata" src={component.url}>
        {component.audio_transcript?.transcript && (
          <track
            ref={trackRef}
            src={getSubtitleUrlFromTranscript(component.audio_transcript.transcript)}
            kind="subtitles"
            srcLang="en"
            label="English"
            default
          />
        )}
        This browser does not support the audio element. We recommend using Google Chrome for the best experience.
      </audio>
    ),
    [component],
  );
};

export function AudioContextProvider(props: { children: ReactNode }) {
  const [activeComponentId, setActiveComponentId] = useState(-1);
  const [components, setComponents] = useState<{ [componentId: number]: ComponentInstance }>([]);
  const [players, setPlayers] = useState<Players>({});
  // const [activeCue, setActiveCue] = useState<{ [componentId: number]: string }>({});
  const [productId, setProductId] = useState(0);

  /**
   * Called from IAC <AudioPlayerUI />. Here, we render an <audio /> component
   */
  function registerComponent(instance: ComponentInstance) {
    setComponents((state) => ({
      ...state,
      [instance.component.id]: instance,
    }));
  }

  function stop(componentId: number) {
    players[componentId]?.pause();
    setActiveComponentId(-1);
  }

  function unregisterComponent(componentId: number) {
    setComponents((state) => omit(state, componentId));
  }

  function play(componentId: number) {
    setActiveComponentId(componentId);
    for (const player of Object.values(players)) {
      player.pause();
    }
    players[componentId]?.play();
  }

  function pause(componentId: number) {
    players[componentId]?.pause();
  }

  const audioElements = useMemo(() => {
    function updatePlayer(componentId: number, player: ReturnType<typeof useMedia>) {
      setPlayers((state) => ({
        ...state,
        [componentId]: player,
      }));
    }

    /*
    function onCueChange(componentId: number, subtitle: string) {
      setActiveCue((state) => ({
        ...state,
        [componentId]: subtitle,
      }));
    }
    */

    return Object.values(components).map((component) => (
      <AudioWithUseMedia
        onUpdate={updatePlayer}
        //onCueChange={onCueChange}
        key={component.component.id}
        {...component}
      />
    ));
  }, [components]);

  const children = useMemo(() => {
    return (
      <>
        {props.children}
        {audioElements}
      </>
    );
  }, [audioElements, props.children]);

  return (
    <AudioContext.Provider
      value={{
        activeComponentId,
        activeTrack: components[activeComponentId] || null,
        activePlayer: players[activeComponentId] || null,
        activeCue: useMemo(() => ({}), []),
        registerComponent,
        unregisterComponent,
        play,
        pause,
        stop,
        players,
        productId,
        setProductId,
      }}
    >
      {children}
    </AudioContext.Provider>
  );
}

/** Every 2 minutes, the user is rewarded points while audio is playing */
function usePointsRewarderInterval(isPlaying: boolean, lessonId: number) {
  const timeLastAddedPoints = useRef<number | null>(null);
  const timePlayedBeforePausing = useRef<number | null>(null);
  const dispatch = useSharedDispatch();

  useInterval(
    () => {
      const twoMinutesHavePassed = Boolean(
        !timeLastAddedPoints.current || new Date().getTime() - timeLastAddedPoints.current > 120000,
      );
      if (twoMinutesHavePassed && lessonId) {
        dispatch(
          requestAddPoints({
            rewardType: "iacPlay",
            data: {
              lesson: lessonId,
            },
          }),
        );
        timeLastAddedPoints.current = new Date().getTime();
      }
    },
    isPlaying ? 5000 : null,
  );

  useEffect(() => {
    if (!isPlaying && timeLastAddedPoints.current) {
      timePlayedBeforePausing.current = new Date().getTime() - timeLastAddedPoints.current;
    }
    if (isPlaying && timeLastAddedPoints.current) {
      timeLastAddedPoints.current = new Date().getTime() - (timePlayedBeforePausing?.current || 0);
    }
  }, [isPlaying]);
}

export default AudioContext;
