import { type ForwardedRef, forwardRef, type HTMLAttributes, type ReactNode, useMemo } from "react";

function fixedForwardRef<T, P>(
  render: (props: P, ref: React.Ref<T>) => React.ReactNode,
): (props: P & React.RefAttributes<T>) => React.ReactNode {
  // https://www.totaltypescript.com/forwardref-with-generic-components
  // @ts-expect-error - TS doesn't know about the ref forwarding
  return forwardRef(render) as any;
}

type VirtualizedListProps<T> = HTMLAttributes<HTMLDivElement> & {
  height: number;
  index: number;
  data: T[];
  windowSize: number;
  renderItem: (item: T, index: number) => ReactNode;
};

function VirtualizedList<T>(props: VirtualizedListProps<T>, ref: ForwardedRef<HTMLDivElement>) {
  const { height, index, data, renderItem, windowSize, ...rest } = props;
  const startIndex = Math.max(0, index - 5);
  const endIndex = Math.min(data.length, index + windowSize);

  const items = useMemo(() => data.slice(startIndex, endIndex), [data, startIndex, endIndex]);

  return (
    <div style={{ height }} {...rest} ref={ref}>
      {items.map((item, index) => renderItem(item, index + startIndex))}
    </div>
  );
}

const ForwardedVirtualizedList = fixedForwardRef(VirtualizedList);

export default ForwardedVirtualizedList;
