import Button, { type ButtonProps } from "../Button";
import { forwardRef } from "react";

import { clsx } from "clsx";

const defaultProps: ButtonProps = {
  color: "primary",
  size: "medium",
};

const colors: Record<string, { active: string; disabled: string; default: string }> = {
  primary: {
    active: "text-white border-2 border-brand bg-brand",
    disabled: "text-brand2 border-2 border-brand2 hover:bg-white hover:!text-brand2 bg-transparent",
    default:
      "text-brand dark:text-text1 border-2 border-brand dark:border-text1 hover:bg-brand bg-transparent hover:text-white",
  },
  secondary: {
    active: "text-white border-2 border-rocketblue bg-rocketblue",
    disabled:
      "text-rocketblue-light border-2 border-rocketblue-light hover:bg-rocketblue-light hover:text-rocketblue-light bg-transparent",
    default: "text-rocketblue border-2 border-rocketblue hover:bg-rocketblue bg-transparent hover:text-white",
  },
  success: {
    active: "text-white border-2 border-rocketgreen bg-rocketgreen",
    disabled:
      "text-rocketgreen-extra-light border-2 border-rocketgreen-extra-light hover:bg-rocketgreen-extra-light hover:text-rocketgreen-extra-light bg-transparent",
    default: "text-rocketgreen border-2 border-rocketgreen hover:bg-rocketgreen bg-transparent hover:text-white",
  },
  warning: {
    active: "text-white border-2 border-rocketorange bg-rocketorange",
    disabled:
      "text-rocketorange-light border-2 border-rocketorange-light hover:bg-rocketorange-light hover:text-rocketorange-light bg-transparent",
    default: "text-rocketorange border-2 border-rocketorange hover:bg-rocketorange bg-transparent hover:text-white",
  },
  error: {
    active: "text-white border-2 border-rocketred bg-rocketred",
    disabled:
      "text-rocketred-extra-light border-2 border-rocketred-extra-light hover:bg-rocketred-extra-light hover:text-bg-rocketred-extra-light bg-transparent",
    default: "text-rocketred border-2 border-rocketred hover:bg-rocketred bg-transparent hover:text-white",
  },
};

const OutlineButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const colorClasses = (() => {
    const colorBase = colors[props.color || "primary"];
    if (props.active) {
      return colorBase?.active;
    }
    if (props.disabled) {
      return colorBase?.disabled;
    }
    return colors.primary?.default;
  })();

  return (
    <Button ref={ref} {...props} className={clsx(colorClasses, props.className)}>
      {props.children}
    </Button>
  );
});

OutlineButton.defaultProps = defaultProps;

export default OutlineButton;
