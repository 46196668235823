/* eslint-disable react-refresh/only-export-components */
import type { Phrase, PhraseString } from "@rocketlanguages/types";
import type { RRStateStatusFlag, RatedPhrase } from "../../hooks/useRocketRecord/types";
import { clsx } from "clsx";
import { isRTL } from "../../utils";
import { type ReactNode, createRef, useContext, useMemo } from "react";
import { useSharedSelector } from "../../store";
import { LiteralStringNotationButton } from "../Notation";
import PhraseStringNotationButtons from "../Phrasebox/includes/PhraseStringNotationButtons";
import useRocketRecord from "../../hooks/useRocketRecord/useRocketRecord";
import Voice from "../Phrasebox/includes/RocketRecord/Voice";
import AudioRecorder from "../Phrasebox/includes/RocketRecord/AudioRecorder";
import { getSpeechRecognitionLocale, useShouldUseNodeSpeechApi } from "../Phrasebox/includes/RocketRecord/utils";
import useActiveCourse from "../../hooks/useActiveCourse";
import { RomanizedWritingSystemIds, WritingSystemIds } from "../../utils/constants";
import { PhraseStringTable } from "../Phrasebox/includes/PhraseStringTable";
import { shallowEqual } from "react-redux";
import { TextDiff } from "../../ui/TextDiff";
import { PhraseboxContext } from "./context";
import { ErrorText } from "./components/ErrorText";
import {
  Hotkeys,
  PlayButton,
  RecordButton,
  RecordPlaybackButton,
  RocketRecordRatingButton,
  StoreRatingButton,
} from "./Buttons";
import type { Change } from "diff";
import useParseVocab from "../../hooks/useParseVocab";
import type { MarkdownToJSX } from "markdown-to-jsx";
import RocketMarkdown from "../Lesson/MarkdownComponent/includes/RocketMarkdown";
import { PhraseboxDebugPanel } from "./components/DebugPanel";

type NewPhraseboxProps = {
  rocketRecord: ReturnType<typeof useRocketRecord>;
  children: ReactNode;
};

export function Phrasebox(props: NewPhraseboxProps) {
  const value = useMemo(
    () => ({
      rocketRecord: props.rocketRecord,
      phrase: props.rocketRecord.phrase,
      phraseAudioRef: createRef<HTMLAudioElement>(),
      replayAudioRef: createRef<HTMLAudioElement>(),
    }),
    [props.rocketRecord],
  );
  return <PhraseboxContext.Provider value={value}>{props.children}</PhraseboxContext.Provider>;
}

export function PhraseboxWithRocketRecord(props: { phrase: Phrase; children: React.ReactNode }) {
  const { phrase } = props;
  const rocketRecord = useWebRocketRecord({ phrase });
  return <Phrasebox rocketRecord={rocketRecord}>{props.children}</Phrasebox>;
}

Phrasebox.DebugPanel = function DebugPanel() {
  const debugEnabled = useSharedSelector((state) => state.preferences.debugEnabled);
  if (!debugEnabled) {
    return null;
  }

  return <PhraseboxDebugPanel />;
};

Phrasebox.Notations = function Notations(props: {
  className?: string;
  /** Hides the phrase string notations */
  onlyShowLiteralString?: boolean;
}) {
  const phrase = useContext(PhraseboxContext)?.phrase;
  if (!phrase) {
    return null;
  }
  return (
    <PhraseboxNotations
      className={props.className}
      phraseStrings={props.onlyShowLiteralString ? null : phrase.strings}
      literalString={phrase.literal_string}
    />
  );
};

export function PhraseboxNotations(props: {
  className?: string;
  phraseStrings: PhraseString[] | null;
  literalString: string | null;
}) {
  const { literalString } = props;
  return (
    <div
      className={clsx("absolute -top-6 right-0 flex flex-row items-center justify-center space-x-2", props.className)}
    >
      {props.phraseStrings?.map((phraseString) => {
        if (phraseString.notations.length === 0) {
          return null;
        }
        return <PhraseStringNotationButtons phraseString={phraseString} key={`notations.${phraseString.id}`} />;
      })}
      {literalString && <LiteralStringNotationButton literalString={literalString} />}
    </div>
  );
}

/**
 * Main container of the phrasebox. Apply background colors here
 *
 * Reverses the row on RTL courses
 */
Phrasebox.RowLayout = function PhraseboxRowLayout(props: { children: ReactNode; className?: string }) {
  const isRtl = isRTL(useContext(PhraseboxContext)?.phrase.course_id || 0);
  return (
    <div
      className={clsx(
        "relative flex break-inside-avoid flex-row gap-4",
        isRtl && "flex-row-reverse text-right",
        props.className,
      )}
    >
      {props.children}
    </div>
  );
};

/**
 * Applies text alignment for RTL languages
 */
Phrasebox.SpeechLayout = function PhraseboxSpeechLayout(props: { children: ReactNode; className?: string }) {
  const isRtl = isRTL(useContext(PhraseboxContext)?.phrase.course_id || 0);
  return <div className={clsx("break-inside-avoid", isRtl && "text-right", props.className)}>{props.children}</div>;
};

Phrasebox.ButtonGrid = function ButtonGrid(props: { className?: string; children: ReactNode }) {
  return (
    <div className={clsx("grid grid-cols-phrasebox-2 grid-rows-phrasebox-2 items-center gap-2", props.className)}>
      {props.children}
    </div>
  );
};

export function useWebRocketRecord(props: {
  phrase: Phrase;
  initialResult?: RatedPhrase;
  initialBlobUrl?: string;
  persistBlobs?: boolean;
  onRecordFinish?: (params: { phraseId: number; result?: RatedPhrase; blobUrl?: string }) => void;
  disableVoiceRecognition?: boolean;
  lessonId?: number;
}) {
  const { phrase } = props;
  const shouldUseNodeSpeechApi = useShouldUseNodeSpeechApi(props.phrase);
  const activeCourse = useActiveCourse();

  return useRocketRecord({
    Voice,
    AudioRecorder,
    shouldUseNodeSpeechApi,
    locale: getSpeechRecognitionLocale({ phrase, course: activeCourse }),
    platform: "web",
    ...props,
  });
}

function InlineParagraph(props: { children: ReactNode }) {
  return <p className="inline">{props.children}</p>;
}

type PhraseStringProps = {
  id: number;
  text: string;
  writing_system_id: number;
  color?: string;
  disableVocabUnderline?: boolean;
  className?: string;
};

Phrasebox.String = function PhraseString({
  id,
  text: phraseText,
  writing_system_id: writingSystemId,
  color,
  disableVocabUnderline,
  className,
}: PhraseStringProps) {
  const { text, markdownOptions } = useParseVocab(phraseText, writingSystemId, {
    color,
    disableVocabUnderline,
  });

  const markdownOptionsWithInlineParagraph = useMemo(
    () =>
      ({
        ...markdownOptions,
        overrides: {
          ...markdownOptions.overrides,
          p: {
            component: InlineParagraph,
          },
        },
      }) satisfies MarkdownToJSX.Options,
    [markdownOptions],
  );

  const textProps = useMemo(() => {
    return {
      className: clsx(`ws-${writingSystemId}`, className),
      style: { color },
    };
  }, [color, writingSystemId, className]);

  const isTable = Boolean(text.indexOf("|") > -1);

  if (isTable) {
    return (
      <PhraseStringTable
        id={id}
        text={text}
        textProps={textProps}
        markdownOptions={markdownOptionsWithInlineParagraph}
      />
    );
  }

  return (
    <div {...textProps}>
      <RocketMarkdown options={markdownOptionsWithInlineParagraph}>{text}</RocketMarkdown>
    </div>
  );
};

/**
 * Displays speech results
 */
Phrasebox.Speech = function RocketRecordSpeech() {
  const context = useContext(PhraseboxContext);

  if (!context?.rocketRecord) {
    throw new Error("rocketRecord must be initialized within a Phrasebox to use this component");
  }

  const state = context.rocketRecord.useRocketRecordState(
    (state) => ({
      flag: state.flag,
      result: state.result,
      blobUrl: state.blobUrl,
    }),
    shallowEqual,
  );

  if (!context || !state) {
    return null;
  }

  const phraseString = context.phrase.strings[0];

  if (!phraseString) {
    return null;
  }

  const { flag, result } = state;

  return (
    <PhraseboxSpeech
      fetchingTranscript={flag.status === "FINISHING" && flag.fetchingTranscript}
      status={flag.status}
      phraseString={phraseString}
      result={result}
    />
  );
};

export function PhraseboxSpeech({
  status,
  result,
  phraseString,
  fetchingTranscript,
}: {
  status: RRStateStatusFlag;
  fetchingTranscript: boolean;
  result: RatedPhrase | undefined;
  phraseString: PhraseString;
}) {
  if (status === "INACTIVE") {
    if (result?.ratingLevel === 3) {
      return (
        <Phrasebox.String
          {...phraseString}
          text={phraseString.text.replace(/<[^>]*>/gim, "")}
          color="#00BE68"
          // Clear the strong font weight
          className="font-semibold [&_>_*_strong]:!font-normal"
        />
      );
    }
    if (result?.diffResult) {
      return <Phrasebox.TextDiff diff={result.diffResult} />;
    }
  }

  if (status === "ACTIVE") {
    if (result?.rawTranscription) {
      return (
        <div
          className={clsx("font-semibold text-[--speech-color]", `ws-${phraseString.writing_system_id}`)}
        >{`${result.rawTranscription}..`}</div>
      );
    }
    return <div className="font-semibold text-missileaccent">Speak now...</div>;
  }

  if (status === "FINISHING") {
    if (fetchingTranscript && !result?.rawTranscription) {
      return <div className="font-semibold text-[--speech-color]">Checking pronunciation...</div>;
    }
    if (result?.rawTranscription) {
      return (
        <div
          className={clsx("font-semibold text-[--speech-color]", `ws-${phraseString.writing_system_id}`)}
        >{`${result.rawTranscription}..`}</div>
      );
    }
    // Prevent any layout jank by keeping the same "Speak now..." text between ACTIVE -> FINISHING -> INACTIVE
    return <div className="font-semibold text-missileaccent">Speak now...</div>;
  }

  return null;
}

Phrasebox.ErrorText = ErrorText;

Phrasebox.TextDiff = function PhraseboxTextDiff(props: { diff: Change[] }) {
  const context = useContext(PhraseboxContext);
  if (!context) {
    throw new Error("TextDiff must be used within a PhraseboxContext");
  }

  const phraseString = context.phrase.strings[0];

  return (
    <span className={clsx(phraseString && `ws-${phraseString.writing_system_id}`)}>
      <TextDiff writingDiff={props.diff} isRTL={isRTL(context.phrase.course_id)} />
    </span>
  );
};

type StringsProps = {
  children?: (
    props: PhraseString,
    index: number,
    /** These are the filtered phrase strings */
    filteredItems: PhraseString[],
  ) => ReactNode;
  filteredWritingSystemIds?: number[];
};

const DefaultChildren = (phraseString: PhraseString) => <Phrasebox.String {...phraseString} />;

/**
 * Renders each string (in separate rows) associated with a specific phrase:
 *
 * e.g. `["ellos", "they (masculine + plural)"]`
 */
Phrasebox.Strings = function Strings({ children, filteredWritingSystemIds }: StringsProps) {
  const ctx = useContext(PhraseboxContext);

  if (!ctx) {
    throw new Error("Phrasebox must be used within a PhraseboxContext");
  }

  const phrase = ctx.phrase;

  const rtl = isRTL(phrase.course_id);
  const romanizationHidden = useSharedSelector((state) => !!Number(state.user.preferences?.hide_romanized));
  const kanaHidden = useSharedSelector((store) => !!Number(store.user.preferences?.hide_kana));

  const Children = children || DefaultChildren;

  const filteredPhraseStrings = phrase.strings.filter((phraseString) => {
    if (
      !phraseString.text ||
      (romanizationHidden && isRomanizedString(phraseString)) ||
      (kanaHidden && isKanaString(phraseString)) ||
      !!filteredWritingSystemIds?.includes(phraseString.writing_system_id)
    ) {
      return false;
    }

    return true;
  });

  return (
    <>
      {filteredPhraseStrings.map((phraseString, i) => (
        <PhraseStringComponent
          key={phraseString.id}
          phraseString={phraseString}
          index={i}
          filteredPhraseStrings={filteredPhraseStrings}
          childrenWrapper={Children}
          rtl={rtl}
        />
      ))}
    </>
  );
};

function PhraseStringComponent({
  phraseString,
  index,
  rtl,
  childrenWrapper,
  filteredPhraseStrings,
}: {
  phraseString: PhraseString;
  index: number;
  rtl: boolean;
  childrenWrapper: (props: PhraseString, index: number, filteredItems: PhraseString[]) => ReactNode;
  filteredPhraseStrings: PhraseString[];
}) {
  const { text, markdownOptions } = useParseVocab(phraseString.text, phraseString.writing_system_id);

  const markdownOptionsWithInlineParagraph = useMemo(
    () =>
      ({
        ...markdownOptions,
        overrides: {
          ...markdownOptions.overrides,
          p: {
            component: InlineParagraph,
          },
        },
      }) satisfies MarkdownToJSX.Options,
    [markdownOptions],
  );

  const isTable = useMemo(() => Boolean(text.indexOf("|") > -1), [text]);

  if (isTable) {
    return (
      <div
        className={clsx("mb-1 flex flex-1", index === 0 && "text-lg", rtl && "flex-row-reverse text-right")}
        key={phraseString.id}
      >
        <PhraseStringTable
          id={phraseString.id}
          text={text}
          textProps={{
            className: clsx(`ws-${phraseString.writing_system_id}`),
            style: {},
          }}
          markdownOptions={markdownOptionsWithInlineParagraph}
        />
      </div>
    );
  }

  return (
    <div
      key={`ps.${phraseString.id}`}
      className={clsx("flex flex-1", index === 0 && "text-lg", rtl && "flex-row-reverse text-right")}
    >
      {childrenWrapper(phraseString, index, filteredPhraseStrings)}
    </div>
  );
}

Phrasebox.ErrorText = ErrorText;

function isRomanizedString(phraseString: PhraseString) {
  return RomanizedWritingSystemIds.includes(phraseString.writing_system_id);
}

function isKanaString(phraseString: PhraseString) {
  return WritingSystemIds.hiragana_katakana === phraseString.writing_system_id;
}

/**
 * Applies spacing and alignment (e.g. RTL) to the strings container
 */
Phrasebox.StringsContainer = function StringsContainer(props: { children: ReactNode; className?: string }) {
  const { className, children } = props;
  const phrase = useContext(PhraseboxContext)?.phrase;
  if (!phrase) {
    console.warn("Phrasebox.StringsContainer: no phrase found");
    return null;
  }

  const isRtl = isRTL(phrase.course_id || 0);

  return (
    <div data-phraseid={phrase.id} className={clsx("w-full space-y-1 break-words", isRtl && "text-right", className)}>
      {children}
    </div>
  );
};

Phrasebox.Hotkeys = Hotkeys;
Phrasebox.RecordPlaybackButton = RecordPlaybackButton;
Phrasebox.RecordButton = RecordButton;
Phrasebox.PlayButton = PlayButton;
Phrasebox.StoreRatingButton = StoreRatingButton;
Phrasebox.RocketRecordRatingButton = RocketRecordRatingButton;

export function LessonPhrasebox(props: {
  phrase: Phrase;
  children?: ReactNode;
  RatingButtonElement?: ReactNode | null;
  disableVocabUnderline?: boolean;
}) {
  const rocketRecord = useWebRocketRecord({
    phrase: props.phrase,
  });

  const debugEnabled = useSharedSelector((state) => state.preferences.debugEnabled);
  const canSeeNotations = debugEnabled || !!props.phrase.show_notations;
  const hasNotations =
    !!props.phrase.literal_string || (canSeeNotations && props.phrase.strings.some((s) => s.notations.length > 0));

  const hasMoreThanOneWritingSystem = props.phrase.strings.length > 2;
  const canRecord = props.phrase.disable_voice_recognition === 0;
  const RatingButtonElement = props.RatingButtonElement;

  return (
    <div className={clsx(hasNotations && "pt-6")}>
      <Phrasebox rocketRecord={rocketRecord}>
        <div className="relative">
          {props.children}
          <div
            className={clsx(
              "rounded-2xl border-2 border-missilestroke p-4 dark:bg-missilesurfacelight",
              hasNotations && "rounded-tr-none",
            )}
          >
            <Phrasebox.Notations onlyShowLiteralString={!canSeeNotations} />

            <Phrasebox.RowLayout>
              <Phrasebox.PlayButton
                className="bg-missilesurfacedark text-missilebrand hover:bg-gray-300 dark:text-white hover:dark:bg-gray-600"
                useDarkWaveform
              />
              <Phrasebox.StringsContainer>
                <Phrasebox.Strings>
                  {(phraseString, index, items) => {
                    const isLast = index === items.length - 1;
                    const shouldShowBottomBorder = hasMoreThanOneWritingSystem && !isLast;
                    return (
                      <div
                        className={
                          shouldShowBottomBorder ? "mb-2 w-full border-b border-b-missilestroke pb-2" : undefined
                        }
                      >
                        <Phrasebox.String {...phraseString} disableVocabUnderline={props.disableVocabUnderline} />
                      </div>
                    );
                  }}
                </Phrasebox.Strings>
              </Phrasebox.StringsContainer>
            </Phrasebox.RowLayout>

            {canRecord && (
              <>
                <Phrasebox.RowLayout className="h-6 items-center">
                  {typeof RatingButtonElement === "undefined" ? <Phrasebox.StoreRatingButton /> : RatingButtonElement}
                  <div className="mb-2 flex-1 border-b border-b-missilestroke pb-2" />
                </Phrasebox.RowLayout>

                <Phrasebox.RowLayout className="items-center pt-2">
                  <Phrasebox.RecordButton className="hover:bg-missileaccent/90 bg-missileaccent text-white" />

                  <Phrasebox.SpeechLayout className="flex-1">
                    <Phrasebox.Speech />
                    <Phrasebox.ErrorText />
                    <Phrasebox.DebugPanel />
                  </Phrasebox.SpeechLayout>

                  <Phrasebox.RecordPlaybackButton
                    className="bg-missilesurfacedark text-missilebrand hover:bg-gray-300 dark:text-white hover:dark:bg-gray-600"
                    useDarkWaveform
                  />
                </Phrasebox.RowLayout>
              </>
            )}
          </div>
        </div>
      </Phrasebox>
    </div>
  );
}

export function TrimmedLessonPhrasebox(props: { phrase: Phrase; children?: ReactNode; RatingButton?: ReactNode }) {
  const rocketRecord = useWebRocketRecord({
    phrase: props.phrase,
  });

  const debugEnabled = useSharedSelector((state) => state.preferences.debugEnabled);
  const canSeeNotations = debugEnabled || !!props.phrase.show_notations;
  const hasNotations =
    !!props.phrase.literal_string || (canSeeNotations && props.phrase.strings.some((s) => s.notations.length > 0));

  const hasMoreThanOneWritingSystem = props.phrase.strings.length > 2;

  return (
    <div className={clsx(hasNotations && "pt-6")}>
      <Phrasebox rocketRecord={rocketRecord}>
        <div className="relative">
          {props.children}
          <div
            className={clsx(
              "rounded-2xl border-2 border-missilestroke p-4 dark:bg-missilesurfacelight",
              hasNotations && "rounded-tr-none",
            )}
          >
            <Phrasebox.Notations onlyShowLiteralString={!canSeeNotations} />

            <Phrasebox.RowLayout>
              <Phrasebox.PlayButton
                className={clsx(
                  "bg-missilesurfacedark text-missilebrand dark:text-white",
                  !props.phrase.audio_url && "invisible",
                )}
                useDarkWaveform
              />
              <Phrasebox.StringsContainer>
                <Phrasebox.Strings>
                  {(phraseString, index, items) => {
                    const isLast = index === items.length - 1;
                    const shouldShowBottomBorder = hasMoreThanOneWritingSystem && !isLast;
                    return (
                      <div
                        className={
                          shouldShowBottomBorder ? "mb-2 w-full border-b border-b-missilestroke pb-2" : undefined
                        }
                      >
                        <Phrasebox.String {...phraseString} />
                      </div>
                    );
                  }}
                </Phrasebox.Strings>
              </Phrasebox.StringsContainer>
            </Phrasebox.RowLayout>
          </div>
        </div>
      </Phrasebox>
    </div>
  );
}
