import { useMemo } from "react";
import useActiveCueIndex from "./useActiveCueIndex";
import VirtualizedList from "./VirtualizedList";
import classes from "./SearchResultsOverlay.module.scss";
import TranscriptLine from "./TranscriptLine";
import useMedia from "../../../../hooks/useMedia";
import { normalize } from "../../../../utils/stringUtils";
import { clsx } from "clsx";

type SearchResultsOverlayProps = {
  subtitles: TextTrackCue[];
  query: string;
  height: number;
  player: ReturnType<typeof useMedia>;
  clearSearch: () => void;
};

export default function SearchResultsOverlay({
  subtitles,
  query,
  height,
  player,
  clearSearch,
}: SearchResultsOverlayProps) {
  const activeIndex = useActiveCueIndex(player.id, subtitles);

  const filteredSubtitles = useMemo(() => {
    if (!query) {
      return subtitles;
    }
    const normalizedQuery = normalize(query);
    // @ts-ignore
    return subtitles.filter((_subtitle) => normalize(_subtitle.text)?.includes(normalizedQuery));
  }, [query, subtitles]);

  return (
    <div className={clsx("box-content overflow-auto pr-2", classes.scrollbar)} style={{ height }}>
      <VirtualizedList
        data={filteredSubtitles}
        windowSize={20}
        index={0}
        className={classes.searchOverlayContainer}
        height={height}
        renderItem={(subtitle, index) => {
          return (
            <TranscriptLine
              showTimestamp
              key={`result.${subtitle.id}`}
              activeIndex={activeIndex}
              index={index}
              subtitle={subtitle}
              onClick={() => {
                player.seek(subtitle.startTime + 0.1);
                clearSearch();
              }}
            />
          );
        }}
      />
    </div>
  );
}
