import { SimpleModal, RoundedButton } from "@rocketlanguages/ui";
import { useState } from "react";
import useTranslation from "../../../../hooks/useTranslation";
import { clsx } from "clsx";

export default function ResetTestButton(props: { testName: string; onConfirmReset: () => void; className?: string }) {
  const t = useTranslation();
  const [showConfirmReset, setShowConfirmReset] = useState(false);
  return (
    <>
      <RoundedButton
        className={clsx(
          "border border-missilebrand font-semibold text-missilebrand hover:bg-missilebrand hover:text-white dark:bg-missilebrand dark:text-white",
          props.className,
        )}
        onClick={() => {
          setShowConfirmReset(true);
        }}
      >
        {t("reset-{rateableTest}", {
          rateableTest: props.testName,
        })}
      </RoundedButton>
      <SimpleModal
        isOpen={showConfirmReset}
        onClose={() => {
          setShowConfirmReset(false);
        }}
        onEscapeKeyDown={() => {
          setShowConfirmReset(false);
        }}
        onPointerDownOutside={() => {
          setShowConfirmReset(false);
        }}
      >
        <h4 className="mb-2">Confirm Reset</h4>
        <div className="mb-4">
          <p className="mb-2">Are you sure you want to reset your progress?</p>
        </div>
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          <RoundedButton
            onClick={() => {
              setShowConfirmReset(false);
            }}
            className="hover:dark:bg-missilebrand/90 w-full border border-missilebrand font-semibold text-missilebrand hover:bg-missilebrand hover:text-white dark:bg-missilebrand dark:text-white"
          >
            {t("cancel")}
          </RoundedButton>
          <RoundedButton
            onClick={() => {
              setShowConfirmReset(false);
              props.onConfirmReset();
            }}
            className="hover:dark:bg-missileaccent/90 w-full bg-missileaccent font-semibold text-white"
          >
            {t("confirm")}
          </RoundedButton>
        </div>
      </SimpleModal>
    </>
  );
}
