import { memo } from "react";
import { clsx } from "clsx";
import type useMedia from "../../../../hooks/useMedia";
import { secondsToMinutesAndSeconds } from "../../../../utils";

type TranscriptLineProps = {
  index: number;
  activeIndex: number;
  subtitle: TextTrackCue & { text?: string };
  player?: ReturnType<typeof useMedia>;
  showTimestamp?: boolean;
  onClick?: () => void;
};

function TranscriptLine({ index, activeIndex, subtitle, player, showTimestamp, onClick }: TranscriptLineProps) {
  const isActive = activeIndex === index;

  const handleLineClick = () => {
    if (player?.isPlaying) {
      player?.seekAndPlay(subtitle.startTime + 0.1);
    } else {
      player?.seek(subtitle.startTime + 0.1);
    }
  };

  return (
    <div data-index={index}>
      <div
        className={clsx(
          "flex cursor-pointer gap-2 py-1 hover:text-missiledark",
          isActive && "font-bold",
          !isActive && "text-slate-400",
        )}
        onClick={onClick || handleLineClick}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleLineClick();
          }
        }}
      >
        {showTimestamp ? <div>{secondsToMinutesAndSeconds(subtitle.startTime)}</div> : null}
        <div>{"text" in subtitle ? subtitle.text : ""}</div>
      </div>
    </div>
  );
}

const MemoizedTranscriptLine = memo(TranscriptLine);

export default MemoizedTranscriptLine;
