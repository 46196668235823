export type MeomizeCacheNode<TArgs, TVal> = {
  /** previous node. */
  prev: MeomizeCacheNode<TArgs, TVal> | undefined;
  /** next node. */
  next: MeomizeCacheNode<TArgs, TVal> | undefined;
  /** function arguments for cache entry. */
  args: TArgs;
  /** function result. */
  val: TVal;
};

export type MemoizeOptions = {
  maxSize?: number;
  comparisonFunction?: (a: readonly unknown[], b: readonly unknown[]) => boolean;
};

export type MemoizedFunction<F extends (...args: any[]) => any> = F & {
  clear: () => void;
  getCache: () => [
    MeomizeCacheNode<Parameters<F>, ReturnType<F>> | undefined,
    MeomizeCacheNode<Parameters<F>, ReturnType<F>> | undefined,
    number,
  ];
};

function areInputsEqual(a: readonly unknown[], b: readonly unknown[]) {
  // Check whether node arguments match arguments length
  if (a.length !== b.length) {
    return false;
  }

  // Check whether node arguments match arguments values
  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }

  return true;
}

/**
 * Accepts a function to be memoized, and returns a new memoized function, with
 * optional options.
 */
export function memoize<F extends (...args: any[]) => any>(fn: F, options?: MemoizeOptions): MemoizedFunction<F> {
  let size = 0;

  let head: MeomizeCacheNode<Parameters<F>, ReturnType<F>> | undefined;

  let tail: MeomizeCacheNode<Parameters<F>, ReturnType<F>> | undefined;

  const comparisonFunction = options?.comparisonFunction ?? areInputsEqual;

  const memoized = (...args: Parameters<F>) => {
    let node = head;

    while (node) {
      const isMatch = comparisonFunction(node.args, args);
      if (!isMatch) {
        node = node.next;
        continue;
      }

      // At this point we can assume we've found a match

      // Surface matched node to head if not already
      if (node !== head) {
        // As tail, shift to previous. Must only shift if not also
        // head, since if both head and tail, there is no previous.
        if (node === tail) {
          tail = node.prev;
        }

        // Adjust siblings to point to each other. If node was tail,
        // this also handles new tail's empty `next` assignment.
        node.prev!.next = node.next;
        if (node.next) {
          node.next.prev = node.prev;
        }

        node.next = head;
        node.prev = undefined;
        head!.prev = node;
        head = node;
      }

      // Return immediately
      return node.val;
    }

    // No cached value found. Continue to insertion phase:
    node = {
      prev: undefined,
      next: undefined,
      args: args,

      // Generate the result from original function
      // eslint-disable-next-line prefer-spread
      val: fn.apply(null, args),
    };

    // Don't need to check whether node is already head, since it would
    // have been returned above already if it was

    // Shift existing head down list
    if (head) {
      head.prev = node;
      node.next = head;
    } else {
      // If no head, follows that there's no tail (at initial or reset)
      tail = node;
    }

    // Trim tail if we're reached max size and are pending cache insertion
    if (size === options?.maxSize) {
      tail = tail!.prev;
      tail!.next = undefined;
    } else {
      size++;
    }

    head = node;

    return node.val;
  };

  memoized.clear = function () {
    head = undefined;
    tail = undefined;
    size = 0;
  };

  memoized.getCache = function () {
    return Object.freeze([head, tail, size] as const);
  };

  // Ignore reason: There's not a clear solution to create an intersection of
  // the function with additional properties, where the goal is to retain the
  // function signature of the incoming argument and add control properties
  // on the return value.

  // @ts-ignore
  return memoized;
}
