import { call, put, takeLatest, select } from "redux-saga/effects";
import * as constants from "./constants";
import * as actions from "./actions";
import API from "../../res/Api";
import { type ActionType } from "typesafe-actions";
import { activeProductSelector } from "../selectors";

/**
 * Resets a phrase test
 */
function* asyncResetTest(action: ActionType<typeof actions.asyncResetTest>) {
  const { collectionId, rateableTestId } = action.payload;
  try {
    const activeProduct: ReturnType<typeof activeProductSelector> = yield select(activeProductSelector);

    if (!activeProduct) {
      throw new Error("No active product/lesson found");
    }

    // Reset test rating
    yield put(actions.resetTest({ collectionId }));

    const resetTest = () =>
      API.get(
        [
          "v2/reset-test/{rateableTest}",
          {
            rateableTest: rateableTestId,
          },
        ],
        {
          productId: activeProduct.id,
        },
      );

    // Reset request
    yield call(resetTest);

    // Success resetting test
    yield put(actions.resetTestSuccess({ collectionId }));
  } catch (e) {
    yield put(actions.resetTestError({ collectionId }));
  }
}

// Any watchers go in here. They get forked in the Root Saga
export default [takeLatest(constants.ASYNC_RESET_TEST, asyncResetTest)];
