import { useReducer, type ReactNode, useLayoutEffect } from "react";
import { AudioContextProvider } from "../ui/Audio/AudioContext";
import { Router } from "react-router-dom";
import { TranslationProvider } from "../locales";
import { history } from "../res/router";

/**
 * Allows us to use the `history` object (from @rocketlanguages/shared/res/router)
 * in contexts outside react components (e.g. sagas)
 */
export function GlobalHistoryRouter({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer((_: any, action: any) => action, history);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => history.listen(dispatch), [history]);

  return (
    <Router
      // @ts-ignore
      action={state.action}
      location={state.location}
      navigator={history}
    >
      {children}
    </Router>
  );
}

export default function AppProvider({ children }: { children: ReactNode }) {
  return (
    <GlobalHistoryRouter>
      <TranslationProvider>
        <AudioContextProvider>{children}</AudioContextProvider>
      </TranslationProvider>
    </GlobalHistoryRouter>
  );
}
