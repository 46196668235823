import CircularProgress from "../CircularProgress";
import { clsx } from "clsx";
import { type HTMLProps } from "react";
import { twMerge } from "tailwind-merge";

export function RoundedButton({
  className,
  children,
  type = "button",
  disabled,
  loading,
  ...rest
}: HTMLProps<HTMLButtonElement> & { loading?: boolean }) {
  return (
    <button
      {...rest}
      disabled={disabled}
      className={twMerge(
        clsx(
          "flex h-11 items-center justify-center rounded-3xl px-12 transition-all duration-100",
          disabled && "pointer-events-none opacity-70",
          className,
        ),
      )}
      type={type as "button" | "submit" | "reset" | undefined}
      aria-busy={loading}
    >
      {loading ? <CircularProgress color="#FFF" className="!size-8" /> : <>{children}</>}
    </button>
  );
}
