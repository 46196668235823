import { forwardRef, memo, useEffect, useRef, useState } from "react";
import { secondsToMinutesAndSeconds } from "../../../../utils";
import type useMedia from "../../../../hooks/useMedia";
import { useMediaProgress } from "../../../../hooks/useMedia";
import { useSliderProgress } from "./state";
import * as SliderPrimitive from "@radix-ui/react-slider";
import { Slider as SliderRadix } from "../../../../ui/Slider/Slider";
import {
  SoundHigh as SoundHighIcon,
  SoundLow as SoundLowIcon,
  SoundMin as SoundMinIcon,
  SoundOff as SoundOffIcon,
} from "iconoir-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@rocketlanguages/ui/Tooltip/Tooltip";

type SliderProps = {
  player: ReturnType<typeof useMedia>;
  disabled?: boolean;
  syncProgress?: boolean;
};

const Slider = memo(({ player, disabled, syncProgress }: SliderProps) => {
  const currentPosition = useMediaProgress(player.ref);
  const [seekValue, setSeekValue] = useState<number>(currentPosition);
  const seekingKey = `seeking_${player?.id || 0}` as const;
  const [seeking, setSeeking] = useState(false);
  const value = seeking ? seekValue : currentPosition;

  useEffect(() => {
    if (syncProgress) {
      const key = `id_${player?.id || 0}` as const;
      const newState: { [key: `id_${number}`]: number } = {
        [key]: value,
      };
      useSliderProgress.setState(newState);
    }
  }, [value, player?.id, syncProgress]);

  const positionFormatted = secondsToMinutesAndSeconds(value);

  return (
    <div className="flex w-full items-center justify-center gap-1 text-sm font-semibold">
      <div className="min-w-12 max-w-12 text-center">
        {player?.state.trackDurationSeconds ? positionFormatted : "-"}
      </div>
      <SliderRadix
        disabled={!!disabled}
        min={0}
        max={player.state.trackDurationSeconds}
        value={[value]}
        onValueChange={(arr) => {
          const value = arr.at(0);
          if (!value) {
            return;
          }

          const newState: { [key: `seeking_${number}`]: boolean } = {
            [seekingKey]: true,
          };
          useSliderProgress.setState(newState);
          setSeeking(true);
          setSeekValue(value);
        }}
        onValueCommit={(arr) => {
          const value = arr.at(0);
          if (!value) {
            return;
          }

          player.seek(value);
          const newState: { [key: `seeking_${number}`]: boolean } = {
            [seekingKey]: false,
          };
          useSliderProgress.setState(newState);
          setSeeking(false);
        }}
      />

      <div className="min-w-12 max-w-12 text-center">
        {player?.state.trackDurationSeconds ? player.state.trackDurationSecondsText : "-"}
      </div>
      {player ? <VolumeControl player={player} /> : null}
    </div>
  );
});

function VolumeControl({ player }: { player: ReturnType<typeof useMedia> }) {
  const [volume, setVolume] = useState((player.ref.current?.volume || 0) * 100 || 100);
  const volumePreviousToMute = useRef(volume);

  useEffect(() => {
    const playerRef = player.ref.current;
    if (!playerRef) {
      return;
    }

    const onVolumeChange = () => {
      setVolume(playerRef.volume * 100);
    };

    playerRef.addEventListener("volumechange", onVolumeChange);
    return () => {
      playerRef.removeEventListener("volumechange", onVolumeChange);
    };
  }, [player.ref]);

  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipContent className="border-0 bg-transparent">
          <div className="h-24 rounded-md bg-black/80 p-4">
            <VolumeSlider
              orientation="vertical"
              value={[volume]}
              onValueChange={(arr) => {
                const value = arr.at(0);
                if (!value) {
                  return;
                }

                setVolume(value);
                const playerRef = player.ref.current;
                if (!playerRef) {
                  return;
                }
                playerRef.volume = value / 100;
              }}
              onValueCommit={(arr) => {
                const value = arr.at(0);
                if (!value) {
                  return;
                }
                const playerRef = player.ref.current;
                if (!playerRef) {
                  return;
                }
                playerRef.volume = value / 100;
              }}
              max={100}
            />
          </div>
        </TooltipContent>
        <TooltipTrigger asChild>
          <button
            type="button"
            role="checkbox"
            aria-checked={volume === 0}
            data-tooltip-focus="Mute"
            className="hover:bg-missilesurfacelight/30 flex size-10 min-h-10 min-w-10 items-center justify-center rounded-full text-white"
            onClick={() => {
              const playerRef = player.ref.current;
              const newVolume = volume === 0 ? volumePreviousToMute.current : 0;
              if (volume !== 0) {
                volumePreviousToMute.current = volume;
              }

              if (playerRef) {
                playerRef.volume = newVolume ? newVolume / 100 : 0;
              }
              setVolume(newVolume);
            }}
          >
            {volume === 0 ? <SoundOffIcon strokeWidth={2} /> : null}
            {volume > 0 && volume < 30 ? <SoundMinIcon strokeWidth={2} /> : null}
            {volume >= 30 && volume < 80 ? <SoundLowIcon strokeWidth={2} /> : null}
            {volume >= 80 && volume <= 100 ? <SoundHighIcon strokeWidth={2} /> : null}
          </button>
        </TooltipTrigger>
      </Tooltip>
    </TooltipProvider>
  );
}

const VolumeSlider = forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ ...props }, ref) => {
  const isActive = useRef(false);
  return (
    <SliderPrimitive.Root
      ref={ref}
      orientation="vertical"
      className="relative flex h-full w-full cursor-pointer touch-none select-none flex-col items-center"
      {...props}
      onPointerDown={() => {
        isActive.current = true;
      }}
      onPointerUp={() => {
        isActive.current = false;
      }}
      onPointerLeave={() => {
        if (isActive.current) {
          props.onValueCommit?.([props.value?.[0] ?? 0]);
          isActive.current = false;
        }
      }}
    >
      <SliderPrimitive.Track className="relative w-1 grow overflow-hidden rounded-full bg-missilesurfacedark">
        <SliderPrimitive.Range className="absolute w-full bg-missileaccent" />
      </SliderPrimitive.Track>
      <SliderPrimitive.Thumb className="block size-3 rounded-full bg-white transition-shadow hover:shadow-[0_0_0_8px_rgb(255_255_255_/16%)] focus:shadow-[0_0_0_14px_rgb(255_255_255_/16%)] focus:outline-none focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50" />
    </SliderPrimitive.Root>
  );
});
Slider.displayName = SliderPrimitive.Root.displayName;

export default Slider;
