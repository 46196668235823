import Button, { type ButtonProps } from "../Button";
import { forwardRef } from "react";
import { clsx } from "clsx";
import styles from "./IconButton.module.scss";

export type Labelled = { "aria-label": string } | { "aria-hidden": true } | { title: string };

export type IconButtonProps = Labelled & Omit<ButtonProps, "left" | "fill">;

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
  const { color = "primary", size: propsSize = "medium", className, ...rest } = props;

  const size = (() => {
    switch (propsSize) {
      case "large":
        return "!h-[64px] !w-[64px] !min-w-[64px] !p-0";
      case "medium":
        return "!h-[48px] !w-[48px] !min-w-[48px] !p-0";
      case "small":
        return "!h-[28px] !w-[28px] !min-w-[28px] !p-0";
      default:
        return "!h-[28px] !w-[28px] !min-w-[28px] !p-0";
    }
  })();

  return (
    <Button
      ref={ref}
      color={color}
      className={clsx(size, propsSize && !props.loading && styles[propsSize], className)}
      {...rest}
    />
  );
});

export default IconButton;
